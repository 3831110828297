import React, { useEffect, useState } from 'react';
import {
  FaBars,
  FaArrowCircleLeft,
  FaChevronRight,
  FaSearch,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // to navigate to article detail
import api from '../../api';

function HelpCenter({ categories, selectedCategory, onBack, sidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();

  const [tab, setTab] = useState('faqs'); // 'faqs' or 'articles'
  const [faqs, setFaqs] = useState([]);
  const [articles, setArticles] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [currentSubCategory, setCurrentSubCategory] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Find the active category doc to display its name
  const activeCat = categories.find((cat) => cat._id === selectedCategory);

  useEffect(() => {
    if (!selectedCategory) return;

    setLoading(true);
    setError('');
    setFaqs([]);
    setArticles([]);
    setSubCategories([]);
    setCurrentSubCategory(null);

    const fetchGroupedData = async () => {
      try {
        const { data } = await api.get(
          `/api/help/faqs-and-articles?category=${selectedCategory}`
        );

        // Set FAQs and Articles based on the response
        setSubCategories(data.groupedData || []);
        setFaqs(data.faqs || []); // FAQs directly under the category
        setArticles(data.articles || []); // Articles directly under the category
      } catch (err) {
        console.error('Error fetching grouped data:', err);
        setError('Failed to load FAQs and Articles. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchGroupedData();
  }, [selectedCategory]);

  const handleSubCategoryClick = (subCategoryId) => {
    setCurrentSubCategory(subCategoryId);

    // Find the clicked subcategory's data
    const subCategoryData = subCategories.find((sub) => sub.subCategoryId === subCategoryId);
    if (subCategoryData) {
      setFaqs(subCategoryData.faqs || []);
      setArticles(subCategoryData.articles || []);
    }
  };

  const handleBackToSubCategories = () => {
    setCurrentSubCategory(null);
    setFaqs([]); // Clear FAQs when going back to subcategories
    setArticles([]);
  };

  const lowerSearch = searchTerm.toLowerCase();

  const filteredFaqs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(lowerSearch) ||
    faq.answer.toLowerCase().includes(lowerSearch)
  );

  const filteredArticles = articles.filter((article) => {
    const combinedText = (
      article.title +
      article.summary +
      article.content +
      (article.publisher?.firstName || '') +
      (article.publisher?.lastName || '')
    ).toLowerCase();
    return combinedText.includes(lowerSearch);
  });

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const handleArticleClick = (slug) => {
    navigate(`/help/article/${slug}`);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col pb-20">
      {/* Header */}
      <header className="sticky top-0 bg-white shadow-md w-full px-4 md:px-5 lg:px-6 py-8 flex flex-wrap items-center justify-between gap-2 ">
        <button
          onClick={currentSubCategory ? handleBackToSubCategories : onBack}
          className="inline-flex items-center text-green-700 hover:underline"
        >
          <FaArrowCircleLeft className="mr-2" />
          {currentSubCategory ? 'Back to Subcategories' : 'Back'}
        </button>

        <h1 className="text-2xl font-bold text-green-700">
          {activeCat ? activeCat.name : 'Help Center'}
        </h1>

        <div className="relative w-full md:w-64">
          <input
            type="text"
            placeholder="Search FAQs or Articles..."
            className="w-full py-2 pl-8 pr-2 border rounded focus:ring-2 focus:ring-green-300 text-sm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className="absolute top-2 left-2 text-gray-400" />
        </div>

        <button
          className="md:hidden text-gray-700 flex items-center"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <FaBars />
        </button>
      </header>

      {/* Tabs */}
      <div className="bg-white w-full border-b border-gray-200">
        <div className="flex">
          {/* FAQs Tab */}
          <button
            onClick={() => setTab('faqs')}
            className={`relative px-4 py-2 font-semibold ${tab === 'faqs' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'
              }`}
          >
            FAQs
            {/* Red Pulsing Dot */}
            {tab !== 'faqs' && (
              <>
                <span className="absolute inline-block ml-1 w-2.5 h-2.5 bg-purple-500 rounded-full animate-ping"></span>
                <span className="absolute inline-block ml-1 w-2.5 h-2.5 bg-purple-500 rounded-full"></span>
              </>
            )}
          </button>

          {/* Articles Tab */}
          <button
            onClick={() => setTab('articles')}
            className={`relative px-4 py-2 font-semibold ${tab === 'articles' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'
              }`}
          >
            Articles
            {/* Red Pulsing Dot */}
            {tab !== 'articles' && (
              <>
                <span className="absolute inline-block ml-1 w-2.5 h-2.5 bg-red-500 rounded-full animate-ping"></span>
                <span className="absolute inline-block ml-1 w-2.5 h-2.5 bg-red-500 rounded-full"></span>
              </>
            )}
          </button>
        </div>
      </div>



      {/* Content */}
      <main className="p-4 flex-1 overflow-y-auto">
        {loading ? (
          <div className="flex justify-center items-center py-10">
            <div className="w-12 h-12 border-4 border-green-300 border-dashed rounded-full animate-spin" />
          </div>
        ) : error ? (
          <div className="text-center py-10 text-red-500">{error}</div>
        ) : subCategories.length > 0 && !currentSubCategory ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {subCategories.map((sub) => (
              <div
                key={sub.subCategoryId}
                className="border border-gray-200 bg-gradient-to-r from-emerald-50 via-white to-gray-100 p-3 rounded-lg  hover:shadow-md transition duration-300 cursor-pointer group flex justify-between items-center"
                onClick={() => handleSubCategoryClick(sub.subCategoryId)}
              >
                {/* Subcategory Name */}
                <h2 className="text-lg font-semibold text-gray-800 group-hover:text-emerald-300 transition duration-300">
                  {sub.subCategory}
                </h2>

                {/* Icon on the right */}
                <div className="bg-emerald-100 text-emerald-200 p-2 rounded-full group-hover:bg-emerald-100 transition duration-300">
                  <FaChevronRight className="w-5 h-5" />
                </div>
              </div>
            ))}
          </div>

        ) : (
          <>
            {tab === 'faqs' && (
              <>
                {filteredFaqs.length === 0 ? (
                  <div className="text-center text-gray-600 py-10">
                    <p className="text-lg font-semibold text-gray-800">
                      No FAQs found for{' '}
                      <span className="text-green-700 font-bold">
                        {currentSubCategory
                          ? subCategories.find((sub) => sub.subCategoryId === currentSubCategory)?.subCategory
                          : activeCat?.name}
                      </span>.
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 p-4 rounded-md mb-4 relative flex items-start">
                      <div className="mr-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-yellow-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 2a7 7 0 00-7 7c0 2.386.895 4.038 2.16 5.505.745.881 1.495 1.73 1.495 3.495V20h6v-1.999c0-1.765.75-2.614 1.495-3.495C18.105 13.038 19 11.386 19 9a7 7 0 00-7-7z"
                          />
                        </svg>
                      </div>
                      <p className="text-sm font-medium">
                        Need more detailed guidance? Click on
                        <span
                          className="text-red-600 font-bold animate-pulse cursor-pointer ml-1"
                          onClick={() => {
                            setTab('articles'); // Set the tab to "articles"
                            window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                          }}
                        >
                          articles
                        </span>{' '}
                        above for detailed help!
                      </p>
                    </div>


                    {filteredFaqs.map((faq, index) => (
                      <div
                        key={faq._id}
                        className="bg-white p-4 rounded-md shadow mb-4 hover:shadow-lg cursor-pointer transition"
                        onClick={() => toggleFaq(index)}
                      >
                        <div className="flex justify-between items-center">
                          <h3 className="text-lg font-medium text-gray-800">{faq.question}</h3>
                          <span className="text-green-600">
                            {openFaqIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        </div>
                        {openFaqIndex === index && (
                          <div
                            className="prose text-gray-600 mt-3"
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          />
                        )}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            {tab === 'articles' && (
              <>
                {filteredArticles.length === 0 ? (
                  <div className="text-center text-gray-600 py-10">
                    <p className="text-lg font-semibold text-gray-800">
                      No Articles found for{' '}
                      <span className="text-green-700 font-bold">
                        {currentSubCategory
                          ? subCategories.find((sub) => sub.subCategoryId === currentSubCategory)?.subCategory
                          : activeCat?.name}
                      </span>.
                    </p>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {filteredArticles.map((article) => (
                      <div
                        key={article._id}
                        onClick={() => handleArticleClick(article.slug)}
                        className="border border-gray-200 bg-gradient-to-r from-green-100 via-indigo to-blue-50 p-4 rounded-md shadow hover:shadow-lg transition cursor-pointer"
                      >
                        {/* Thumbnail (if any) */}
                        {article.imageUrl && (
                          <img
                            src={article.imageUrl}
                            alt={article.title}
                            className="w-full h-40 object-cover rounded-md mb-3"
                          />
                        )}

                        <h2 className="text-xl font-bold text-gray-800">{article.title}</h2>
                        <p className="text-sm text-gray-600 italic mt-1">{article.summary}</p>

                        {/* Publisher Info */}
                        <div className="mt-3 text-xs text-gray-500 flex items-center">
                          {article.publisher && article.publisher.profilePhoto && (
                            <img
                              src={article.publisher.profilePhoto}
                              alt={`${article.publisher.firstName} ${article.publisher.lastName}`}
                              className="w-8 h-8 rounded-full object-cover mr-2"
                            />
                          )}
                          {article.publisher && (
                            <span>
                              Published by {article.publisher.firstName} {article.publisher.lastName}
                            </span>
                          )}
                        </div>

                        {/* Publish Date */}
                        {article.publishedAt && (
                          <div className="mt-1 text-xs text-gray-500">
                            on{' '}
                            {new Date(article.publishedAt).toLocaleDateString('en-US', {
                              weekday: 'long', // e.g., Monday
                              year: 'numeric', // e.g., 2024
                              month: 'long', // e.g., January
                              day: 'numeric', // e.g., 1
                            })}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                )}
              </>
            )}
          </>
        )}
      </main>
    </div>
  );
}

export default HelpCenter;

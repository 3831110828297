// pdfUtils.js
import jsPDF from "jspdf";
import "jspdf-autotable";

export async function generateReceipt(withdrawalDetails, user) {
  const doc = new jsPDF();

  // Set the opacity for the watermark
  doc.setGState(new doc.GState({ opacity: 0.2 }));
  const currentYear = new Date().getFullYear();
  const watermarkText = `Verdant Charity ${currentYear}`;

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.setTextColor(150, 150, 150);
  for (let i = 0; i < doc.internal.pageSize.getHeight(); i += 20) {
    for (let j = 0; j < doc.internal.pageSize.getWidth(); j += 50) {
      doc.text(watermarkText, j, i, { angle: 45 });
    }
  }
  doc.setGState(new doc.GState({ opacity: 1 }));

  // Fetch images
  const paperPlaneImage = await getImageBase64(
    "https://res.cloudinary.com/dx6jw8k0m/image/upload/v1709472568/send-money__1_-removebg-preview_hg9tip.png"
  );
  const barcodeImage = await getImageBase64(
    "https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg"
  );
  const logoImage = await getImageBase64(
    "https://www.verdantcharity.org/dashboard_images/verdant_design.png"
  );

  // Fetch avatar image
  const avatarImage = await getImageBase64(
    user?.primaryInfo?.profilePhotoUrl ||
      "https://media.istockphoto.com/id/1435566677/vector/placeholder-icon-illustration.jpg?s=612x612&w=0&k=20&c=mMfFWN3fGUOv5S75bC5tmMSzFDNoqiCQFfVoMTsC4n0="
  );

  // ===============================
  //   AVATAR + TITLE + SUBTITLE
  // ===============================
  // Layout variables
  const pageWidth = doc.internal.pageSize.getWidth();
  const avatarSize = 30; // Square size of the avatar image
  const avatarX = 20;    // Left margin for avatar
  const avatarY = 30;    // Top position for avatar
  
  const textStartX = avatarX + avatarSize + 10; // Position title and subtitle after avatar
  const titleY = avatarY + avatarSize / 2 - 3; // Center-align title with avatar
  const subtitleY = titleY + 8; // Place subtitle just below title
  
  // Add a thin green border around the avatar
  const borderThickness = 0.1; // Thin border
  doc.setDrawColor(34, 139, 34); // Green color
  doc.setLineWidth(borderThickness);
  doc.rect(avatarX - borderThickness, avatarY - borderThickness, avatarSize + borderThickness * 2, avatarSize + borderThickness * 2, "S");
  
  // Add avatar image
  doc.addImage(avatarImage, "PNG", avatarX, avatarY, avatarSize, avatarSize);
  

  // Add title
  doc.setFont("times", "bold");
  doc.setFontSize(22);
  doc.setTextColor(34, 139, 34);
  doc.text("Verdant Charity Withdrawal Receipt", textStartX, titleY);

// Add subtitle (center-aligned)
doc.setFont("helvetica", "normal");
doc.setFontSize(12);
doc.setTextColor(0, 0, 0);

// Calculate the center position for the subtitle
const subtitleText = "Moving Money for Better";
const subtitleWidth = doc.getStringUnitWidth(subtitleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
const subtitleX = (pageWidth - subtitleWidth) / 2; // Center position

doc.text(subtitleText, subtitleX, subtitleY);


// Set draw color to black for the horizontal line
doc.setDrawColor(0, 0, 0);

// Draw a horizontal line below title and subtitle
const lineY = subtitleY + 10; // Space below subtitle
doc.line(20, lineY, pageWidth - 20, lineY);


  // Add paper plane image (centered below line)
  doc.addImage(
    paperPlaneImage,
    "PNG",
    doc.internal.pageSize.getWidth() / 2 - 20,
    lineY + 10,
    40,
    30
  );

  // Personalized Greeting
  doc.setFont("courier", "bold");
  doc.setFontSize(18);
  doc.setTextColor(0, 112, 186);
  const firstName = user?.primaryInfo?.firstName
    ? user.primaryInfo.firstName.toUpperCase()
    : "Customer";
  doc.text(
    `Hi ${firstName},`,
    doc.internal.pageSize.getWidth() / 2,
    lineY + 50,
    { align: "center" }
  );

  // Layout for amount square
  const pageCenter = doc.internal.pageSize.getWidth() / 2;
  const squareSize = 40;
  const squareX = pageCenter - squareSize - 15;
  const squareY = lineY + 60;

  doc.setDrawColor(0, 112, 186);
  doc.setFillColor(0, 112, 186);
  doc.rect(squareX, squareY, squareSize, squareSize, "F");

  doc.setFont("times", "bold");
  doc.setFontSize(10);
  doc.setTextColor(255, 255, 255);
  doc.text("Total Amount:", squareX + 5, squareY + 15, {
    maxWidth: squareSize - 10,
  });
  doc.setFontSize(14);
  doc.text(
    `${withdrawalDetails.currency} ${withdrawalDetails.amount}`,
    squareX + 5,
    squareY + 25,
    { maxWidth: squareSize - 10 }
  );

  // Transaction details to the right of the square
  let detailsStartY = squareY;
  const detailsX = pageCenter - 10;
  doc.setFont("courier", "normal");
  doc.setFontSize(10);
  doc.setTextColor(0, 0, 0);

  if (withdrawalDetails.type === "Bank") {
    doc.text(`Bank: ${withdrawalDetails.bank}`, detailsX, detailsStartY);
    doc.text(
      `Account No: ${withdrawalDetails.accountNo}`,
      detailsX,
      (detailsStartY += 6)
    );
    doc.text(
      `Beneficiary Name: ${withdrawalDetails.beneficiaryName}`,
      detailsX,
      (detailsStartY += 6)
    );
  } else if (withdrawalDetails.type === "Paypal") {
    doc.text(`Email: ${withdrawalDetails.email}`, detailsX, detailsStartY);
  } else if (withdrawalDetails.type === "MobileMoney") {
    doc.text(`Phone Number: ${withdrawalDetails.phoneNumber}`, detailsX, detailsStartY);
    doc.text(`Provider: ${withdrawalDetails.provider}`, detailsX, (detailsStartY += 6));
  }

  detailsStartY += 12;
  doc.text(`Channel: ${withdrawalDetails.type}`, detailsX, detailsStartY);
  doc.text(
    `Amount: ${withdrawalDetails.amount} ${withdrawalDetails.currency}`,
    detailsX,
    (detailsStartY += 6)
  );
  doc.text(`Status: ${withdrawalDetails.status}`, detailsX, (detailsStartY += 6));
  doc.text(`Date: ${formatDate(withdrawalDetails.createdAt)}`, detailsX, (detailsStartY += 6));

  // Add barcode image
  doc.addImage(
    barcodeImage,
    "PNG",
    doc.internal.pageSize.getWidth() / 2 - 80,
    detailsStartY + 20,
    160,
    30
  );

  // Footer
  doc.setFont("courier", "italic");
  doc.setFontSize(8);
  const footerTextStartY = doc.internal.pageSize.getHeight() - 30;
  const footerText =
    "If you encounter any issues, please contact support at support@verdantcharity.org. " +
    "Payment is secured with DLocal. Ravel Global Pay, Apt. 992, 54072 Larson Stravenue, Port Kymside, IA 70661-2925. " +
    "For support: support@verdantcharity.org | Hotline: +1 385 233 9891";
  doc.text(footerText, 20, footerTextStartY, { maxWidth: 180 });

  // Add logo and transaction ID
  doc.setFont("helvetica", "normal");
  const logoWidth = 30;
  const logoHeight = 15;
  doc.addImage(logoImage, "PNG", 10, 5, logoWidth, logoHeight);

  const transactionId = `${withdrawalDetails.withdrawalId}`;
  const transactionIdWidth =
    doc.getStringUnitWidth(transactionId) * (doc.internal.getFontSize() / doc.internal.scaleFactor);
  doc.text(transactionId, doc.internal.pageSize.getWidth() - transactionIdWidth - 10, 5);

  // Save PDF
  doc.save(`Withdrawal_Receipt_${withdrawalDetails.withdrawalId}.pdf`);
}


 // Helper function to replace S3 URL with CloudFront URL
 const replaceWithCloudFrontUrl = (url) => {
    if (!url || typeof url !== "string") return url; // Validate URL
    return url.replace(
      "verdantcharity.s3.amazonaws.com",
      "d30sleee6j32ev.cloudfront.net"
    );
  };

// Helper to get image as base64 with fallback
// Helper to get image as base64 with fallback
async function getImageBase64(
    url,
    fallbackUrl = "https://media.istockphoto.com/id/1435566677/vector/placeholder-icon-illustration.jpg?s=612x612&w=0&k=20&c=mMfFWN3fGUOv5S75bC5tmMSzFDNoqiCQFfVoMTsC4n0=",
    isFallback = false // Tracks if this is already the fallback attempt
  ) {
    try {
      const replacedUrl = replaceWithCloudFrontUrl(url);
      const response = await fetch(replacedUrl, {
        method: "GET"
      });
  
      if (!response.ok) {
        throw new Error(`Image fetch failed for ${replacedUrl}.`);
      }
  
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error(`Error fetching image from ${url}:`, error);
  
      // Use fallback URL only if this is not already a fallback attempt
      if (!isFallback && fallbackUrl) {
        console.warn(`Attempting to fetch fallback image.`);
        return getImageBase64(fallbackUrl, null, true); // Mark fallback attempt
      }
  
      // Final fallback: Return a placeholder string to prevent further retries
      console.error(`Both primary and fallback image fetch failed.`);
      return null;
    }
  }
  
  

// Format date
function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
}

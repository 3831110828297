import React, { useState } from 'react';
import Confetti from 'react-confetti';
import Lottie from 'lottie-react';
import successAnimation from "../../lottie/success-animation.json";
import successConfetti from '../../lottie/success-confetti.json';
import { FaSpinner, FaShieldAlt, FaCheckCircle } from 'react-icons/fa';
import { HiOutlineChevronDoubleUp } from "react-icons/hi";
import api from '../../../api';
import { useUser } from "../../context";

const Deposit = ({ setActiveComponent }) => {
    const { user, login } = useUser();
    const [depositDetails, setDepositDetails] = useState({
        amount: '',
        phoneNumber: '',
        currency: 'KES',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [inputError, setInputError] = useState('');
    const [success, setSuccess] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isPolling, setIsPolling] = useState(false);

    // Gather user currencies from context
    const currencies =
        user?.accounts?.map((acc) => ({ currency: acc.currency, balance: acc.balance })) ||
        [];

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setDepositDetails({ ...depositDetails, [name]: value });

        if (name === 'amount') {
            if (parseFloat(value) < 50) {
                setInputError('Minimum deposit is 50 KES.');
            } else {
                setInputError('');
            }
        }
    };

    // Submit deposit
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (inputError || !depositDetails.amount) return;

        setIsSubmitting(true);
        setError('');
        setSuccessMessage('');

        try {
            const response = await api.post(
                '/api/deposits/deposit',
                {
                    amount: parseFloat(depositDetails.amount),
                    currency: depositDetails.currency,
                    initiatorPhoneNumber: user?.phoneNumber,
                    phoneNumber: depositDetails.phoneNumber,
                },
                {
                    headers: { Authorization: `Bearer ${user.token}` },
                }
            );

            if (response.data && response.status === 200) {
                setSuccessMessage('STK sent. Enter your PIN to complete the transaction.');
                setIsPolling(true);

                // Poll the deposit status
                const checkoutRequestId = response.data.CheckoutRequestID;
                pollDepositStatus(checkoutRequestId);
            }
        } catch (err) {
            setIsSubmitting(false);
            setError('An error occurred while processing the transaction. Check your connection.');
            console.error('Deposit error:', err);
        }
    };

    // Polling logic
    const pollDepositStatus = (checkoutRequestId) => {
        const pollInterval = 5000;
        const maxRetries = 12;
        let retries = 0;

        const poller = setInterval(async () => {
            try {
                const depResponse = await api.get(`/api/deposits/deposit/${checkoutRequestId}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                const depositData = depResponse.data;

                if (depositData.isSuccess) {
                    // Refresh user info
                    const userRes = await api.get('/api/auth/info', {
                        headers: { Authorization: `Bearer ${user.token}` },
                    });
                    if (userRes.status === 200) {
                        login({ ...user, ...userRes.data });
                    }

                    clearInterval(poller);
                    finalizeSuccess();
                } else if (depositData.error && !depositData.isSuccess) {
                    // If deposit fails
                    clearInterval(poller);
                    setIsSubmitting(false);
                    setIsPolling(false);
                    setError(depositData.error);
                    setSuccessMessage('');
                }

                retries++;
                if (retries >= maxRetries) {
                    clearInterval(poller);
                    setIsSubmitting(false);
                    setIsPolling(false);
                    setError('Transaction timeout. Please try again.');
                    setSuccessMessage('');
                }
            } catch (err) {
                console.error('Error polling deposit status:', err);
                clearInterval(poller);
                setIsSubmitting(false);
                setIsPolling(false);
                setError('Error checking deposit status. Please check deposit history and try again.');
                setSuccessMessage('');
            }
        }, pollInterval);
    };

    const finalizeSuccess = () => {
        setIsSubmitting(false);
        setIsPolling(false);
        setSuccessMessage('');
        setShowConfetti(true);
        setSuccess(true);

        setTimeout(() => {
            setSuccess(false);
            setShowConfetti(false);
            setActiveComponent('CurrenciesContainer');
        }, 5000);
    };

    // UI
    return (
        <div className="bg-white rounded-lg shadow-md border-2 border-emerald-100 p-4 relative">
            {showConfetti && <Confetti />}

            {success ? (
                /* Final success screen */
                <div className="flex flex-col items-center w-full p-4">
                    <Lottie
                        animationData={successConfetti}
                        className="w-full h-64 md:h-80"
                        style={{ position: 'relative' }}
                    />
                    <p className="text-lg font-bold text-emerald-700 mt-4 text-center">
                        Deposit Successful!
                    </p>
                    <button
                        onClick={() => setActiveComponent('CurrenciesContainer')}
                        className="
              mt-4
              text-emerald-500
              border
              border-emerald-500
              hover:bg-emerald-500
              hover:text-white
              transition
              duration-300
              py-2
              px-4
              rounded
              text-sm
            "
                    >
                        Done
                    </button>
                </div>
            ) : (
                /* Deposit Form */
                <>
                    {/* Top Title & Security Note */}
                    <div className="text-center mb-4">
                        <h2 className="text-xl font-extrabold text-emerald-600">
                            Make a Deposit
                        </h2>
                        <p className="text-xs text-gray-500 flex items-center justify-center mt-1">
                            <FaShieldAlt className="mr-1 text-green-600" />
                            100% Secure Payment
                        </p>
                    </div>
                    {/* Icon */}
                    <div className="flex justify-center mb-6">
                        <img
                            src="https://d30sleee6j32ev.cloudfront.net/Withdrawals/images/withdrawal_placeholders/deposit_ad.png"
                            alt="Withdrawal Icon"
                            className="w-20 h-auto"
                        />
                    </div>

                    {/* Error alert */}
                    {error && (
                        <div className="mb-4 p-3 text-sm bg-red-50 border-l-4 border-red-500 text-red-600 rounded">
                            {error}
                        </div>
                    )}

                    {/* Success message */}
                    {successMessage && (
                        <div className="mb-4 p-3 text-sm text-green-700 bg-green-50 border-l-4 border-green-500 rounded">
                            {successMessage}
                        </div>
                    )}

                    {/* Deposit form */}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Phone Number */}
                        <div>
                            <label
                                htmlFor="phoneNumber"
                                className="block mb-1 text-sm font-medium text-gray-700"
                            >
                                Phone Number
                            </label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                value={depositDetails.phoneNumber}
                                onChange={handleChange}
                                placeholder="e.g., +254712345678"
                                className="
                  w-full
                  text-sm
                  p-2
                  border
                  rounded
                  focus:outline-none
                  focus:ring-emerald-500
                  focus:border-emerald-500
                "
                            />
                        </div>

                        {/* Amount */}
                        <div>
                            <label
                                htmlFor="amount"
                                className="block mb-1 text-sm font-medium text-gray-700"
                            >
                                Amount (KES)
                            </label>
                            <input
                                type="number"
                                name="amount"
                                value={depositDetails.amount}
                                onChange={handleChange}
                                className={`
                  w-full
                  text-sm
                  p-2
                  border
                  rounded
                  focus:outline-none
                  ${inputError
                                        ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                                        : 'focus:ring-emerald-500 focus:border-emerald-500'
                                    }
                `}
                                placeholder="Minimum 50 KES"
                            />
                            {inputError && (
                                <p className="text-xs text-red-500 mt-1">{inputError}</p>
                            )}
                        </div>

                        {/* Currency */}
                        <div>
                            <label
                                htmlFor="currency"
                                className="block mb-1 text-sm font-medium text-gray-700"
                            >
                                Currency
                            </label>
                            <select
                                name="currency"
                                value={depositDetails.currency}
                                onChange={handleChange}
                                className="
                  w-full
                  text-sm
                  p-2
                  border
                  rounded
                  focus:outline-none
                  focus:ring-emerald-500
                  focus:border-emerald-500
                "
                            >
                                <option value="">Select Currency</option>
                                {currencies.map((c, idx) => (
                                    <option key={idx} value={c.currency}>
                                        {c.currency}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Submit */}
                        <button
                            type="submit"
                            disabled={isSubmitting || inputError}
                            className={`
                flex
                justify-center
                items-center
                w-full
                text-white
                py-2
                px-4
                mt-2
                rounded
                transition
                duration-300
                ${isSubmitting || inputError
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-emerald-500 hover:bg-emerald-600'
                                }
              `}
                        >
                            {isSubmitting ? (
                                <>
                                    <FaSpinner className="animate-spin mr-2" />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    <HiOutlineChevronDoubleUp className="mr-2" />
                                    Deposit Funds
                                </>
                            )}
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default Deposit;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../context'; // Import user context for authorization
import api from '../../api'; // Adjust to your API helper
import 'react-toastify/dist/ReactToastify.css';

function HelpArticleList() {
    const navigate = useNavigate();
    const { user } = useUser(); // Get user for authorization
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false); // Prevent double-click on delete
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null); // Article being deleted

    // Fetch articles on component mount
    useEffect(() => {
        const fetchArticles = async () => {
            setLoading(true);
            try {
                const { data } = await api.get('/api/help/articles', {
                    headers: {
                        Authorization: `Bearer ${user?.token || ''}`, // Pass user token for authorization
                    },
                });
                setArticles(data.articles || []);
            } catch (error) {
                console.error('Error fetching articles:', error);
                toast.error('Failed to load articles');
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [user?.token]);

    // Open delete confirmation modal
    const handleDeleteClick = (article) => {
        setSelectedArticle(article);
        setDeleteModal(true);
    };

    // Confirm and delete article
    const confirmDelete = async () => {
        if (!selectedArticle) return;
        setDeleting(true);

        try {
            await api.delete(`/api/help/articles/${selectedArticle._id}`, {
                headers: {
                    Authorization: `Bearer ${user?.token || ''}`, // Pass user token for authorization
                },
            });
            toast.success('Article deleted successfully!');
            setArticles(articles.filter((a) => a._id !== selectedArticle._id)); // Remove article from the list
        } catch (error) {
            console.error('Error deleting article:', error);
            toast.error('Failed to delete article');
        } finally {
            setDeleting(false);
            setDeleteModal(false);
            setSelectedArticle(null);
        }
    };

    // Cancel deletion
    const cancelDelete = () => {
        setDeleteModal(false);
        setSelectedArticle(null);
    };

    // Handle edit
    const handleEdit = (articleId) => {
        navigate(`/admin/help-articles/edit/${articleId}`);
    };

    // Handle view
    const handleView = (slug) => {
        navigate(`/help/article/${slug}`);
    };

    if (loading) {
        return <div className="text-center py-4">Loading articles...</div>;
    }

    return (
        <div className="max-w-6xl mx-auto p-6">
            <h1 className="text-2xl font-bold mb-6">Help Articles</h1>

            {/* Table */}
            {articles.length === 0 ? (
                <p>No articles found.</p>
            ) : (
                <table className="min-w-full bg-white border border-gray-200 shadow-md rounded">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="py-3 px-4 text-left">Title</th>
                            <th className="py-3 px-4 text-left">Category</th>
                            <th className="py-3 px-4 text-left">Published</th>
                            <th className="py-3 px-4 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {articles.map((article) => (
                            <tr key={article._id} className="border-t">
                                <td className="py-3 px-4">{article.title}</td>
                                <td className="py-3 px-4">
                                    {typeof article.category === 'object'
                                        ? article.category.name
                                        : article.category}
                                </td>
                                <td className="py-3 px-4">{article.published ? 'Yes' : 'No'}</td>
                                <td className="py-3 px-4 flex space-x-3">
                                    <button
                                        onClick={() => handleView(article.slug)}
                                        className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition"
                                    >
                                        View
                                    </button>
                                    <button
                                        onClick={() => handleEdit(article._id)}
                                        className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDeleteClick(article)}
                                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Delete Confirmation Modal */}
            {deleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this article?</p>
                        <div className="flex justify-end space-x-3 mt-6">
                            <button
                                onClick={cancelDelete}
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmDelete}
                                disabled={deleting}
                                className={`px-4 py-2 bg-red-500 text-white rounded ${deleting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'
                                    }`}
                            >
                                {deleting ? 'Deleting...' : 'Delete'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HelpArticleList;

import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdAttachMoney,
  MdFavorite,
  MdSecurity,
  MdStore,
  MdPeople,
  MdCampaign,
  MdPeopleOutline,
  MdPayment,
  MdGavel,
  MdInfo,
} from 'react-icons/md';
import api from '../../api';

import CategoriesOverview from './CategoriesOverview';
import HelpCenter from './HelpCenter';

const HelpCenterParent = () => {
  const [activeView, setActiveView] = useState('categories'); // 'categories' or 'helpCenter'
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  // Icon mapping for categories
  const categoryIconMapping = {
    "accounts-and-settings": MdAccountCircle,
    "funds-and-transactions": MdAttachMoney,
    "donations-and-giving": MdFavorite,
    "security-and-fraud": MdSecurity,
    "marketplace-and-listings": MdStore,
    "volunteering-and-activities": MdPeople,
    "campaigns-and-fundraising": MdCampaign,
    "community-and-connections": MdPeopleOutline,
    "transfers-and-payments": MdPayment,
    "taxes-and-compliance": MdGavel,
    "about-us": MdInfo,
  };

  useEffect(() => {
    // Fetch categories on mount
    const fetchCategories = async () => {
      try {
        setLoadingCategories(true);
        const { data } = await api.get('/api/help/categories');
        setCategories(data.categories || []);
      } catch (err) {
        console.error('Failed to fetch categories:', err);
      } finally {
        setLoadingCategories(false);
      }
    };
    fetchCategories();
  }, []);

  const handleSelectCategory = (categoryId) => {
    setSelectedCategory(categoryId);
    setActiveView('helpCenter');
    setSidebarOpen(false);
  };

  const handleBackToCategories = () => {
    setActiveView('categories');
  };

  return (
    <div className="min-h-screen bg-gray-50 overflow-x-hidden flex">
      {/* Sidebar */}
      <aside
        className={`absolute md:static top-0 left-0 w-64 h-full bg-white border-r shadow z-40
          transform transition-transform duration-300
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          md:translate-x-0`}
      >
        <div className="sticky top-0 bg-white z-50">
          {/* Header for the Sidebar */}
          <div className="p-4 border-b flex items-center justify-between">
            <h2 className="text-xl font-bold text-green-700">Help Center</h2>
            <button
              className="md:hidden text-gray-600"
              onClick={() => setSidebarOpen(false)}
            >
              ✕
            </button>
          </div>
        </div>

        {/* Sidebar Category Navigation */}
        <nav className="p-4 mt-2 overflow-y-auto">
          {loadingCategories ? (
            <p className="text-gray-500 text-sm">Loading categories...</p>
          ) : categories.length === 0 ? (
            <p className="text-gray-500 text-sm">No categories available</p>
          ) : (
            categories.map((cat) => {
              const IconComponent = categoryIconMapping[cat.slug] || FaBars;
              return (
                <button
                  key={cat._id}
                  onClick={() => handleSelectCategory(cat._id)}
                  className={`flex items-center w-full py-2 px-3 mb-2 rounded transition-colors ${
                    selectedCategory === cat._id ? 'bg-green-100' : 'hover:bg-gray-100'
                  }`}
                >
                  <span className="mr-3 text-lg text-green-700">
                    <IconComponent />
                  </span>
                  <span className="text-sm text-gray-700">{cat.name}</span>
                </button>
              );
            })
          )}
        </nav>
      </aside>

      {/* Main Content */}
      <div className="flex-1">
        {activeView === 'categories' ? (
          <CategoriesOverview
            categories={categories}
            onSelectCategory={handleSelectCategory}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        ) : (
          <HelpCenter
            categories={categories}
            selectedCategory={selectedCategory}
            onBack={handleBackToCategories}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        )}
      </div>
    </div>
  );
};

export default HelpCenterParent;

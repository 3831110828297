// WithdrawalHistory.js
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Lottie from "lottie-react";
import unavailableAnimation from '../../lottie/noLinks.json';
import loadingAnimation from '../../lottie/loading.json';
import { useUser } from "../../context";
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle, FaClock, FaInfoCircle } from 'react-icons/fa';
import { AiOutlineDownload, AiOutlineLoading3Quarters, AiOutlineCheck } from 'react-icons/ai';
import { generateReceipt } from './pdfUtils';  // Adjust the path as necessary

const WithdrawalHistory = ({ onViewDetails }) => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [withdrawals, setWithdrawals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [downloadStatus, setDownloadStatus] = useState({}); // Tracks download state by withdrawal ID

    useEffect(() => {
        if (!user || !user.token) {
            navigate('/login');
        }
    }, [user, navigate]);

    useEffect(() => {
        const fetchWithdrawals = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/api/transactions/withdraw/user-withdrawals', {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    }
                });
                setWithdrawals(response.data);
            } catch (error) {
                console.error("Error fetching withdrawals: ", error);
            }
            setIsLoading(false);
        };

        fetchWithdrawals();
    }, [user.token]);

    const handleViewDetails = (withdrawalId) => {
        onViewDetails(withdrawalId);
    };

    const handleDownload = async (withdrawalSummary) => {
        // Set status to generating for the specific withdrawal
        setDownloadStatus(prev => ({ ...prev, [withdrawalSummary._id]: 'generating' }));

        try {
            // Fetch full withdrawal details before generating the receipt
            const response = await api.get(`/api/transactions/withdrawals/${withdrawalSummary._id}`, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            const fullWithdrawalDetails = response.data;

            // Generate the receipt with complete details
            await generateReceipt(fullWithdrawalDetails, user);

            // Set status to success after generation
            setDownloadStatus(prev => ({ ...prev, [withdrawalSummary._id]: 'success' }));

            // Revert back to idle after 2 seconds
            setTimeout(() => {
                setDownloadStatus(prev => ({ ...prev, [withdrawalSummary._id]: 'idle' }));
            }, 2000);
        } catch (error) {
            console.error("Error generating receipt:", error);
            // Optionally handle error state for download status here
            setDownloadStatus(prev => ({ ...prev, [withdrawalSummary._id]: 'idle' }));
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'pending':
                return <FaClock className="text-yellow-500" />;
            case 'processing':
                return <FaClock className="text-blue-500" />;
            case 'completed':
                return <FaCheckCircle className="text-green-500" />;
            case 'failed':
            case 'cancelled':
                return <FaTimesCircle className="text-red-500" />;
            default:
                return <FaInfoCircle className="text-gray-500" />;
        }
    };

    const getStatusBackground = (status) => {
        switch (status) {
            case 'pending':
                return 'bg-yellow-50';
            case 'processing':
                return 'bg-blue-50';
            case 'completed':
                return 'bg-green-50';
            case 'failed':
            case 'cancelled':
                return 'bg-red-50';
            default:
                return 'bg-gray-50';
        }
    };

    return (
        <div className="flex flex-col flex-grow container mx-auto p-4 bg-white rounded-lg text-center font-harmattan">
            {isLoading ? (
                <div className="flex justify-center items-center py-4">
                    <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
                </div>
            ) : withdrawals.length > 0 ? (
                withdrawals.map((withdrawal, index) => (
                    <div
                        key={index}
                        className={`flex items-center justify-between p-2 mt-3 border rounded ${getStatusBackground(withdrawal.status)}`}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="p-2 rounded-full bg-white shadow">
                                {getStatusIcon(withdrawal.status)}
                            </div>
                            <div className="text-left">
                                <p className="font-semibold text-xs">{withdrawal.withdrawalId}</p>
                                <p className="text-xs text-gray-500">{withdrawal.currency} {withdrawal.amount}</p>
                                <p className={`font-semibold text-xs ${user?.isPremium && (withdrawal.status === 'pending' || withdrawal.status === 'processing')
                                        ? 'text-green-800'
                                        : withdrawal.status === 'pending'
                                            ? 'text-yellow-500'
                                            : withdrawal.status === 'processing'
                                                ? 'text-blue-500'
                                                : withdrawal.status === 'completed'
                                                    ? 'text-green-500'
                                                    : withdrawal.status === 'failed'
                                                        ? 'text-red-500'
                                                        : withdrawal.status === 'cancelled'
                                                            ? 'text-red-500'
                                                            : 'text-black'
                                    }`}>
                                    {user?.isPremium && (withdrawal.status === 'pending' || withdrawal.status === 'processing')
                                        ? 'Queued'
                                        : withdrawal.status.charAt(0).toUpperCase() + withdrawal.status.slice(1)}
                                </p>
                            </div>
                        </div>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => handleViewDetails(withdrawal._id)}
                                className="px-4 py-1 text-xs border border-emerald-500 text-emerald-500 rounded hover:bg-emerald-100 hover:text-emerald-600"
                            >
                                View
                            </button>
                            <button
                                onClick={() => handleDownload(withdrawal)}
                                className="p-2 rounded-full border border-blue-500 text-blue-500 hover:bg-blue-100 hover:text-blue-600 flex items-center justify-center"
                            >
                                {downloadStatus[withdrawal._id] === 'generating' ? (
                                    <AiOutlineLoading3Quarters className="animate-spin" />
                                ) : downloadStatus[withdrawal._id] === 'success' ? (
                                    <AiOutlineCheck className="text-green-500" />
                                ) : (
                                    <AiOutlineDownload />
                                )}
                            </button>

                        </div>
                    </div>
                ))
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <Lottie animationData={unavailableAnimation} style={{ width: 200, height: 200 }} />
                    <p className="text-gray-500 font-semibold mt-4">
                        No Withdrawal Records Found
                    </p>
                </div>
            )}
        </div>
    );
};

export default WithdrawalHistory;

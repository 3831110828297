import React from 'react';
import { FaBars } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdAttachMoney,
  MdFavorite,
  MdSecurity,
  MdStore,
  MdPeople,
  MdCampaign,
  MdPeopleOutline,
  MdPayment,
  MdGavel,
  MdInfo,
} from 'react-icons/md';

const CategoriesOverview = ({ categories, onSelectCategory, sidebarOpen, setSidebarOpen }) => {
  // Icon mapping for categories
  const categoryIconMapping = {
    "accounts-and-settings": MdAccountCircle,
    "funds-and-transactions": MdAttachMoney,
    "donations-and-giving": MdFavorite,
    "security-and-fraud": MdSecurity,
    "marketplace-and-listings": MdStore,
    "volunteering-and-activities": MdPeople,
    "campaigns-and-fundraising": MdCampaign,
    "community-and-connections": MdPeopleOutline,
    "transfers-and-payments": MdPayment,
    "taxes-and-compliance": MdGavel,
    "about-us": MdInfo,
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-100 to-white flex flex-col pb-20">
      {/* Header */}
      <header className="sticky top-0 left-0 right-0 bg-white/70 backdrop-blur-sm w-full px-4 md:px-6 py-8 flex items-center justify-between shadow ">
        {/* Hamburger menu (mobile) */}
        <button
          className="md:hidden text-gray-700 flex items-center"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <FaBars />
        </button>
        <h1 className="text-lg font-extrabold text-green-800">All Categories</h1>
      </header>

      {/* Categories grid */}
      <main className="flex-1 p-4 md:p-6 lg:p-8">
        {categories.length === 0 ? (
          <p className="text-gray-600">No categories found.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {categories.map((category) => {
              // Get the icon for the category or fallback to FaBars
              const IconComponent = categoryIconMapping[category.slug] || FaBars;

              return (
                <div
                  key={category._id}
                  onClick={() => onSelectCategory(category._id)}
                  className="border border-green-200 bg-white/70 backdrop-blur-md rounded-xl p-5 shadow-md 
                             transition transform hover:scale-105 hover:shadow-lg cursor-pointer"
                >
                  <div className="flex items-center mb-3">
                    <div className="flex items-center justify-center w-10 h-10 bg-green-100/50 rounded-full">
                      {/* Render the dynamic icon */}
                      <IconComponent className="text-green-700 text-xl" />
                    </div>
                    <h2 className="ml-4 text-lg font-semibold text-gray-800">
                      {category.name}
                    </h2>
                  </div>
                  <p className="text-sm text-gray-600">
                    Explore topics related to <b>{category.name}</b> and find answers quickly.
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </main>
    </div>
  );
};

export default CategoriesOverview;

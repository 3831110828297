// CommunityMain.js
import React, { useState } from 'react';
import CommunityDirectory from './CommunityDirectory';
import CommunityUserDetail from './CommunityUserDetail';

const CommunityMain = () => {
  // Track which user is currently active/selected; null = none
  const [activeUser, setActiveUser] = useState(null);

  // Callback when a user is selected from the directory
  const handleUserSelect = (user) => {
    setActiveUser(user);
  };

  // Callback to return to the directory
  const handleBack = () => {
    setActiveUser(null);
  };

  // If no user selected, show directory; otherwise show detail
  return (
    <>
      {!activeUser ? (
        <CommunityDirectory onUserSelect={handleUserSelect} />
      ) : (
        <CommunityUserDetail user={activeUser} onBack={handleBack} />
      )}
    </>
  );
};

export default CommunityMain;

import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { FaMoneyCheckAlt, FaExchangeAlt, FaChartLine, FaWallet, FaHistory } from 'react-icons/fa';
import WithdrawalHistory from './WithdrawalHistory';
import TradeHistory from './TradeHistory';
import TransferHistory from './TransferHistory';
import WithdrawalDetail from './WithdrawalDetail';
import TransferDetail from '../transactions/TransferDetail';
import DepositHistory from '../deposits/DepositHistory';
import DepositDetail from '../deposits/DepositDetail';
import ConvertHistory from '../conversion/ConvertHistory';
import ConversionDetail from '../conversion/ConversionDetail';

const HistoryContainer = () => {
    const [activeTab, setActiveTab] = useState('withdrawHistory');
    const [selectedWithdrawalId, setSelectedWithdrawalId] = useState(null);
    const [selectedTransferId, setSelectedTransferId] = useState(null);
    const [selectedDepositId, setSelectedDepositId] = useState(null);
    const [selectedConversionId, setSelectedConversionId] = useState(null);

    const handleViewDetails = (id, type) => {
        if (type === 'withdraw') setSelectedWithdrawalId(id);
        if (type === 'transfer') setSelectedTransferId(id);
        if (type === 'deposit') setSelectedDepositId(id);
        if (type === 'conversion') setSelectedConversionId(id);
        setActiveTab('detail');
    };

    const handleBackToHistory = (type) => {
        if (type === 'withdraw') setSelectedWithdrawalId(null);
        if (type === 'transfer') setSelectedTransferId(null);
        if (type === 'deposit') setSelectedDepositId(null);
        if (type === 'conversion') setSelectedConversionId(null);
        setActiveTab(`${type}History`);
    };

    const tabOptions = [
        { label: 'Withdrawal History', value: 'withdrawHistory', icon: <FaMoneyCheckAlt /> },
        { label: 'Transfer History', value: 'transferHistory', icon: <FaExchangeAlt /> },
        { label: 'Trade History', value: 'tradeHistory', icon: <FaChartLine /> },
        { label: 'Deposit History', value: 'depositHistory', icon: <FaWallet /> },
        { label: 'Conversion History', value: 'conversionHistory', icon: <FaHistory /> },
    ];

    return (
        <>
            {selectedWithdrawalId && (
                <WithdrawalDetail withdrawalId={selectedWithdrawalId} onBack={() => handleBackToHistory('withdraw')} />
            )}
            {selectedTransferId && (
                <TransferDetail transferId={selectedTransferId} onBack={() => handleBackToHistory('transfer')} />
            )}
            {selectedDepositId && (
                <DepositDetail depositId={selectedDepositId} onBack={() => handleBackToHistory('deposit')} />
            )}
            {selectedConversionId && (
                <ConversionDetail conversionId={selectedConversionId} onBack={() => handleBackToHistory('conversion')} />
            )}

            {!selectedWithdrawalId && !selectedTransferId && !selectedDepositId && !selectedConversionId && (
                <div
                    className="w-full bg-gradient-to-tl from-white via-emerald-50 to-white py-8 px-4 sm:px-6 lg:px-8 rounded-2xl shadow-xl border border-emerald-100"
                >
                    <div className="text-center mb-8">
                        <h2 className="text-2xl font-bold text-emerald-600">Transaction History</h2>
                        <p className="text-gray-500 text-sm">
                            View detailed logs of all your transactions, including withdrawals, transfers, deposits, and conversions.
                        </p>
                    </div>

                    <div className="flex flex-col md:flex-row md:space-x-6">
                        {/* Tabs for larger screens */}
                        <div className="hidden md:flex flex-col w-1/3 lg:w-1/4 space-y-4 border-r pr-4">
                            {tabOptions.map((tab) => (
                                <button
                                    key={tab.value}
                                    onClick={() => setActiveTab(tab.value)}
                                    className={`flex items-center px-4 py-3 text-left font-semibold rounded-r-full transition-all ${
                                        activeTab === tab.value
                                            ? 'bg-emerald-100 text-emerald-700 border-l-4 border-emerald-500'
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <span className="mr-2 text-xl">{tab.icon}</span>
                                    {tab.label}
                                </button>
                            ))}
                        </div>

                        {/* Dropdown for small screens */}
                        <div className="md:hidden mb-4 border-b">
                            <Menu as="div" className="relative">
                                <Menu.Button className="flex justify-between w-full px-4 py-2 border rounded text-sm font-medium text-gray-700 transition-colors duration-300 hover:bg-emerald-50">
                                    {tabOptions.find((tab) => tab.value === activeTab)?.label || 'Select History'}
                                    <ChevronDownIcon
                                        className="w-5 h-5 ml-2 text-emerald-500 animate-bounce"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                                <Menu.Items className="absolute w-full mt-1 bg-white border rounded shadow-lg z-10">
                                    {tabOptions.map((tab) => (
                                        <Menu.Item key={tab.value}>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => setActiveTab(tab.value)}
                                                    className={`${
                                                        active ? 'bg-emerald-100' : ''
                                                    } w-full text-left px-4 py-2 text-sm text-gray-700 flex items-center`}
                                                >
                                                    <span className="mr-2 text-lg">{tab.icon}</span>
                                                    {tab.label}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Menu>
                        </div>

                        {/* Content Section */}
                        <div className="md:w-2/3 lg:w-3/4">
                            {activeTab === 'withdrawHistory' && (
                                <WithdrawalHistory onViewDetails={(id) => handleViewDetails(id, 'withdraw')} />
                            )}
                            {activeTab === 'transferHistory' && (
                                <TransferHistory onViewDetails={(id) => handleViewDetails(id, 'transfer')} />
                            )}
                            {activeTab === 'depositHistory' && (
                                <DepositHistory onViewDetails={(id) => handleViewDetails(id, 'deposit')} />
                            )}
                            {activeTab === 'conversionHistory' && (
                                <ConvertHistory onViewDetails={(id) => handleViewDetails(id, 'conversion')} />
                            )}
                            {activeTab === 'tradeHistory' && <TradeHistory />}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HistoryContainer;

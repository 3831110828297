import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../context'; // Import user context
import api from '../../api';

const SubCategoryModal = ({ category, onClose, onSubCategoryCreated }) => {
    const { user } = useUser(); // Access user context
    const [subCategoryName, setSubCategoryName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCreateSubCategory = async () => {
        if (!subCategoryName.trim()) {
            toast.error('Subcategory name is required');
            return;
        }
        setIsSubmitting(true);

        try {
            const { data } = await api.post(
                '/api/help/subcategories',
                {
                    name: subCategoryName,
                    category,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.token}`, // Include the user's token
                    },
                }
            );
            toast.success('Subcategory created successfully');
            onSubCategoryCreated(data.subCategory); // Notify parent with the new subcategory
            onClose(); // Close modal
        } catch (error) {
            console.error('Error creating subcategory:', error);
            toast.error('Failed to create subcategory');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-md shadow-lg w-96">
                <h3 className="text-lg font-bold mb-4">Create Subcategory</h3>
                <input
                    type="text"
                    placeholder="Subcategory name"
                    className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:border-blue-500"
                    value={subCategoryName}
                    onChange={(e) => setSubCategoryName(e.target.value)}
                />
                <div className="flex justify-end space-x-3">
                    <button
                        className="px-4 py-2 bg-gray-300 rounded text-gray-700 hover:bg-gray-400"
                        onClick={onClose}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                        onClick={handleCreateSubCategory}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Creating...' : 'Create'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubCategoryModal;

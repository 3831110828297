import React, { useState, useEffect } from "react";
import {
  FaFilter,
  FaUserCheck,
  FaMapMarkerAlt,
  FaClock,
} from "react-icons/fa";
import { Toaster, toast } from "react-hot-toast";
import { useUser } from "./context"; // Import the user context

// Replace this with your actual Google Maps Geocoding API Key
const GOOGLE_MAPS_API_KEY = "AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE";

// VolunteerConnector API example
const VOLUNTEER_API_BASE_URL = "https://www.volunteerconnector.org/api/search";

const VolunteerDirectory = () => {
  // ======= State =======
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Remote Filter: all, online, inPerson
  const [remoteFilter, setRemoteFilter] = useState("all");

  // Pagination
  const [page, setPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  // For tracking applied state (local demo)
  const [appliedState, setAppliedState] = useState({});

  // Access user information
  const { user } = useUser();
  const fetchOpportunities = async () => {
    const maxRetries = 3; // Maximum number of retry attempts
    const retryDelay = 2000; // Delay between retries in milliseconds
    let attempt = 0;
  
    const fetchData = async () => {
      try {
        setLoading(true);
        setError("");
  
        // Build query params
        const queryParams = new URLSearchParams({
          page,
        });
  
        // Fetch all opportunities
        const response = await fetch(
          `${VOLUNTEER_API_BASE_URL}/?${queryParams.toString()}`
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch volunteer opportunities.");
        }
  
        const data = await response.json();
        const enrichedData = await enrichLocations(data.results || []);
        setOpportunities(enrichedData);
        setNextPageUrl(data.next);
        setPrevPageUrl(data.previous);
      } catch (err) {
        if (attempt < maxRetries) {
          attempt++;
          console.error(
            `Attempt ${attempt} failed. Retrying in ${retryDelay / 1000} seconds...`,
            err
          );
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          return fetchData(); // Retry
        } else {
          console.error("Max retry attempts reached. Unable to fetch opportunities:", err);
          setError(err.message || "Something went wrong.");
        }
      } finally {
        setLoading(false);
      }
    };
  
    await fetchData();
  };
  
  // ======= Effects =======
  useEffect(() => {
    fetchOpportunities();
  }, [page, remoteFilter]);

  
  // Enrich opportunities with location names based on coordinates
  const enrichLocations = async (opps) => {
    const updatedOpportunities = await Promise.all(
      opps.map(async (opp) => {
        if (opp.audience?.scope === "local" && opp.audience.latitude) {
          const cityName = await getCityNameFromCoordinates(
            opp.audience.latitude,
            opp.audience.longitude
          );
          return { ...opp, cityName };
        }
        return opp;
      })
    );
    return updatedOpportunities;
  };

  const getCityNameFromCoordinates = async (lat, lng) => {
    try {
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`;
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK" && data.results?.length > 0) {
        const locality = data.results.find((result) =>
          result.types.some(
            (type) =>
              type === "locality" || type === "administrative_area_level_2"
          )
        );
        return locality?.formatted_address || data.results[0].formatted_address;
      }
    } catch (err) {
      console.error("Failed to get city name: ", err);
    }
    return "Unknown Location";
  };

  // Filter results on the client for remote vs. in-person vs. all
  const finalOpportunities = opportunities.filter((opp) => {
    if (remoteFilter === "all") return true;
    if (remoteFilter === "online") return opp.remote_or_online;
    if (remoteFilter === "inPerson") return !opp.remote_or_online;
    return true;
  });

  // ========== Handlers ==============
  const handleRemoteFilterChange = (e) => {
    setRemoteFilter(e.target.value);
    setPage(1); // Reset to the first page when filter changes
  };

  const handlePageChange = (url) => {
    if (!url) return;
    try {
      const urlParams = new URL(url).searchParams;
      const nextPage = urlParams.get("page");
      setPage(Number(nextPage) || 1);
    } catch (err) {
      console.error("Failed to parse pagination URL.", err);
    }
  };

  const handleApplyClick = (opp) => {
    const firstName = user?.primaryInfo?.firstName || "User";
    const organizationName = opp.organization?.name || "this organization";

    toast.error(
      `${firstName}, upgrade to premium to submit documents and get connected to ${organizationName} within 5 working days.`
    );
  };

  // ========== Render ==============
  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-white px-4 py-6">
      <Toaster />
      <div className="mx-auto max-w-3xl text-sm">
        {/* Title */}
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">
          Volunteer Opportunities
        </h1>

        {/* Filters */}
        <div className="flex flex-col sm:flex-row sm:items-center gap-3 mb-6">
          {/* Remote Filter (All / Online / In-Person) */}
          <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden w-full sm:w-60">
            <span className="px-3 text-gray-400">
              <FaFilter />
            </span>
            <select
              className="w-full border-none focus:outline-none px-2 py-2"
              value={remoteFilter}
              onChange={handleRemoteFilterChange}
            >
              <option value="all">All</option>
              <option value="online">Online</option>
              <option value="inPerson">In-Person</option>
            </select>
          </div>
        </div>

        {/* Loading Indicator */}
        {loading && (
          <p className="text-center text-gray-500">Loading opportunities...</p>
        )}

        {/* Error message */}
        {error && <p className="text-center text-red-500">{error}</p>}

        {/* Directory List */}
        {!loading && finalOpportunities.length === 0 && !error ? (
          <p className="text-gray-500 text-center">
            No volunteer opportunities found. Try another filter.
          </p>
        ) : (
          <div className="space-y-6 mt-4">
            {finalOpportunities.map((opp) => {
              const isApplied = !!appliedState[opp.id];

              // Fallback cover image or org logo
              const coverUrl =
                opp.organization?.logo ||
                "https://source.unsplash.com/600x200/?charity";

              return (
                <div
                  key={opp.id}
                  className="cursor-pointer rounded-lg shadow bg-white hover:shadow-lg transition"
                >
                  {/* Cover Section */}
                  <div className="relative w-full h-32 sm:h-40">
                    <img
                      src={coverUrl}
                      alt="Cover"
                      className="object-cover w-full h-full"
                      onError={(e) => {
                        e.target.src =
                          "https://via.placeholder.com/600x200?text=No+Cover";
                      }}
                    />
                    {/* Overlaid Org Logo (if you want a smaller circle) */}
                    {opp.organization?.logo && (
                      <div className="absolute bottom-0 left-4 transform translate-y-1/2 z-10">
                        <img
                          src={opp.organization.logo}
                          alt={opp.organization.name}
                          className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-4 border-white shadow-lg object-cover"
                          onError={(e) => {
                            e.target.src =
                              "https://via.placeholder.com/48?text=No+Logo";
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {/* Content Section */}
                  <div className="pt-10 px-4 pb-4">
                    {/* Title, Location, Button */}
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                      <div className="min-w-0">
                        <h3 className="text-base font-semibold text-gray-800 truncate">
                          {opp.title}
                        </h3>
                        {/* Location Info */}
                        {opp.audience?.scope === "local" && (
                          <p className="text-xs text-gray-500 flex items-center truncate">
                            <FaMapMarkerAlt className="mr-1 text-gray-400" />
                            {opp.cityName || "Unknown Location"}
                          </p>
                        )}
                      </div>

                      {/* "Apply" button */}
                      <button
                        onClick={() => handleApplyClick(opp)}
                        className="mt-2 sm:mt-0 px-3 py-1 rounded text-xs font-medium bg-gray-200 text-gray-700 hover:bg-gray-300"
                      >
                        Apply
                      </button>
                    </div>

                    {/* Description */}
                    <p className="text-gray-600 text-sm line-clamp-2 mt-3">
                      {opp.description?.replace(/<\/?[^>]+(>|$)/g, "") ||
                        "No description available."}
                    </p>

                    {/* Additional Quick Info */}
                    <div className="flex flex-wrap items-center gap-3 text-xs text-gray-600 mt-2">
                      {/* Times / Dates */}
                      <div className="flex items-center gap-1">
                        <FaClock className="text-gray-500" />
                        <span>{opp.dates || "Ongoing"}</span>
                      </div>
                      {/* Remote vs In Person */}
                      {opp.remote_or_online ? (
                        <span className="flex items-center gap-1 text-green-600 font-semibold">
                          Online
                        </span>
                      ) : (
                        <span className="flex items-center gap-1 text-blue-600 font-semibold">
                          In-Person
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {/* "Load More" - if nextPageUrl or prevPageUrl exist */}
            {(nextPageUrl || prevPageUrl) && (
              <div className="text-center mt-4 flex justify-center gap-4">
                {prevPageUrl && (
                  <button
                    onClick={() => handlePageChange(prevPageUrl)}
                    className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-3 py-2 rounded"
                  >
                    Previous
                  </button>
                )}
                {nextPageUrl && (
                  <button
                    onClick={() => handlePageChange(nextPageUrl)}
                    className="bg-emerald-500 hover:bg-emerald-600 text-white px-3 py-2 rounded"
                  >
                    Load More
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VolunteerDirectory;

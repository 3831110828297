/**
 * TradeReviews.js
 * The "Reviews" tab content with:
 *  - Dummy existing reviews
 *  - Star rating display
 *  - "Write a Review" form
 */

import React, { useState } from 'react';
import { FaRegStar, FaStar, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

// Helper to generate star icons
const StarRating = ({ rating }) => {
  const totalStars = 5;
  return (
    <div className="flex items-center">
      {[...Array(totalStars)].map((_, idx) => {
        const starValue = idx + 1;
        return starValue <= rating ? (
          <FaStar key={starValue} className="text-yellow-400" />
        ) : (
          <FaRegStar key={starValue} className="text-gray-300" />
        );
      })}
    </div>
  );
};

const TradeReviews = () => {
  // Dummy data: existing reviews
  const [reviews, setReviews] = useState([
    {
      id: 1,
      author: 'Lucy Parker',
      text: 'Lucy is an amazing seller! The trade went smoothly, and the points were delivered instantly.',
      rating: 5,
      upvotes: 12,
      downvotes: 1,
    },
    {
      id: 2,
      author: 'Anonymous',
      text: 'Fast response time, reliable, and great communication.',
      rating: 4,
      upvotes: 5,
      downvotes: 0,
    },
    {
      id: 3,
      author: 'Michael Brown',
      text: 'Quick and easy transaction. Highly recommended!',
      rating: 5,
      upvotes: 18,
      downvotes: 0,
    },
    {
      id: 4,
      author: 'Sophia Harris',
      text: 'The points took a bit longer than expected to arrive, but overall, great service.',
      rating: 3,
      upvotes: 6,
      downvotes: 2,
    },
    {
      id: 5,
      author: 'Anonymous',
      text: 'Excellent experience! Will definitely trade again.',
      rating: 5,
      upvotes: 20,
      downvotes: 1,
    },
    {
      id: 6,
      author: 'Emily Johnson',
      text: 'Not as smooth as I hoped, but the seller made things right in the end.',
      rating: 4,
      upvotes: 8,
      downvotes: 4,
    },
    {
      id: 7,
      author: 'Daniel Lee',
      text: 'Highly professional and responsive. Couldn’t have asked for better service.',
      rating: 5,
      upvotes: 15,
      downvotes: 0,
    },
    {
      id: 8,
      author: 'Olivia Martinez',
      text: 'The trade was straightforward, but communication could be improved.',
      rating: 3,
      upvotes: 4,
      downvotes: 5,
    },
    {
      id: 9,
      author: 'Anonymous',
      text: 'I was skeptical at first, but the seller delivered as promised. Great job!',
      rating: 4,
      upvotes: 10,
      downvotes: 1,
    },
    {
      id: 10,
      author: 'Henry Adams',
      text: 'Fantastic experience! Seller is honest and reliable.',
      rating: 5,
      upvotes: 25,
      downvotes: 0,
    },
  ]);
  

  // Local states for new review form
  const [newReviewText, setNewReviewText] = useState('');
  const [newReviewRating, setNewReviewRating] = useState(5);

  // Handle adding a new review
  const handleAddReview = (e) => {
    e.preventDefault();
    if (!newReviewText.trim()) return;

    const newReview = {
      id: reviews.length + 1,
      author: 'You (just now)',
      text: newReviewText,
      rating: newReviewRating,
      upvotes: 0,
      downvotes: 0,
    };
    setReviews([newReview, ...reviews]);
    setNewReviewText('');
    setNewReviewRating(5);
  };

  // Voting (optional)
  const handleVote = (reviewId, type) => {
    setReviews((prevReviews) =>
      prevReviews.map((rev) => {
        if (rev.id === reviewId) {
          if (type === 'up') {
            return { ...rev, upvotes: rev.upvotes + 1 };
          } else {
            return { ...rev, downvotes: rev.downvotes + 1 };
          }
        }
        return rev;
      })
    );
  };

  return (
    <div className="max-w-4xl w-full bg-white p-4 border border-gray-300 my-4 rounded-lg">
      {/* Top Alert or note */}
      <div className="mb-4 p-2 bg-blue-50 border border-blue-200 text-blue-700 text-sm rounded">
        <p>
          Thank you for trusting us! Here are some reviews from real users. Feel free to leave your own feedback.
        </p>
      </div>

      <h2 className="text-emerald-600 font-bold text-lg mb-3">Reviews</h2>

      {/* Existing Reviews */}
      <div className="space-y-4">
        {reviews.map((review) => (
          <div key={review.id} className="border border-gray-200 rounded p-3 text-sm">
            <div className="flex items-center justify-between">
              {/* Star Rating */}
              <StarRating rating={review.rating} />
              {/* Upvote/Downvote (optional) */}
              <div className="flex items-center space-x-3 text-xs">
                <button
                  className="flex items-center space-x-1 text-green-600"
                  onClick={() => handleVote(review.id, 'up')}
                >
                  <FaThumbsUp />
                  <span>{review.upvotes}</span>
                </button>
                <button
                  className="flex items-center space-x-1 text-red-600"
                  onClick={() => handleVote(review.id, 'down')}
                >
                  <FaThumbsDown />
                  <span>{review.downvotes}</span>
                </button>
              </div>
            </div>
            <p className="mt-1 text-gray-600 italic">"{review.text}"</p>
            <p className="mt-1 text-gray-500 text-xs">— {review.author}</p>
          </div>
        ))}
      </div>

      {/* Write a review form */}
      <form onSubmit={handleAddReview} className="mt-6 border-t pt-4">
        <h3 className="font-semibold text-sm text-gray-700 mb-2">Write a Review</h3>
        {/* Rating Selector */}
        <label className="block text-xs mb-1">Your Rating:</label>
        <select
          value={newReviewRating}
          onChange={(e) => setNewReviewRating(Number(e.target.value))}
          className="border border-gray-300 rounded p-1 text-sm mb-2 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
        >
          {[5, 4, 3, 2, 1].map((val) => (
            <option key={val} value={val}>
              {val} Star{val > 1 && 's'}
            </option>
          ))}
        </select>

        <label className="block text-xs mb-1">Your Review:</label>
        <textarea
          rows="3"
          className="w-full border border-gray-300 rounded p-2 text-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
          placeholder="Share your experience..."
          value={newReviewText}
          onChange={(e) => setNewReviewText(e.target.value)}
        />

        <button
          type="submit"
          className="mt-2 bg-emerald-500 text-white px-4 py-2 text-sm rounded hover:bg-emerald-600 transition"
        >
          Submit Review
        </button>
      </form>

      {/* End note */}
      <div className="text-center text-gray-400 text-sm mt-6">
        <em>Thank you for helping us improve!</em>
      </div>
    </div>
  );
};

export default TradeReviews;

import React, { useState, useRef, useCallback } from 'react';
import { FaCloudUploadAlt, FaSpinner, FaLightbulb } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import imageCompression from 'browser-image-compression';
import api from '../../api';
import { useUser } from '../context';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

const ProfilePhotoUploader = ({
  photoType = 'profile', // Can be 'profile' or 'cover'
  existingPhotoUrl,
  onSave,
  onCancel,
}) => {
  const { user, login } = useUser();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(existingPhotoUrl || '');
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [compressionProgress, setCompressionProgress] = useState(0);

  const dropzoneRef = useRef(null);

  // VALIDATION AND CLIENT-SIDE COMPRESSION
  const validateAndCompressFile = useCallback(async (file) => {
    try {
      // Check file type
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setError('Only JPEG or PNG images are allowed.');
        return;
      }

      // Compress the file
      const options = {
        maxSizeMB: 2, // Target size in MB (compressed)
        maxWidthOrHeight: 1920, // Resize dimensions
        useWebWorker: true, // For better performance
        onProgress: (percentage) => {
          setCompressionProgress(percentage);
        },
      };

      const compressedFile = await imageCompression(file, options);

      // Check the size after compression
      const compressedSizeMB = compressedFile.size / (1024 * 1024);
      if (compressedSizeMB > 10) {
        setError('Compressed file is still too large. Please select a smaller file.');
        return;
      }

      // Clear errors and set the compressed file
      setError('');
      setSelectedFile(compressedFile);

      // Preview the compressed image
      const reader = new FileReader();
      reader.onload = (event) => setPreviewUrl(event.target.result);
      reader.readAsDataURL(compressedFile);

      console.log('Original file size:', file.size / 1024 / 1024, 'MB');
      console.log('Compressed file size:', compressedSizeMB, 'MB');
    } catch (err) {
      console.error('Error compressing image:', err);
      setError('Failed to compress image.');
    } finally {
      setCompressionProgress(0); // Reset compression progress
    }
  }, []);

  // DRAG & DROP HANDLERS
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(false);

      const file = e.dataTransfer.files[0];
      if (file) {
        validateAndCompressFile(file);
      }
    },
    [validateAndCompressFile]
  );

  // INPUT HANDLER
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) validateAndCompressFile(file);
  };

  // ACTIONS
  const handleSaveClick = async () => {
    if (!selectedFile) {
      setError('Please select a file first.');
      return;
    }

    setIsSaving(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('type', photoType); // Specify the photo type ('profile' or 'cover')

    try {
      const response = await api.put('/api/uploads/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`, // Token for authentication
        },
      });

      if (response.status === 200) {
        const newImageUrl = response.data.imageUrl;

        // Update context based on photo type
        if (photoType === 'profile') {
          login({
            ...user,
            primaryInfo: {
              ...user.primaryInfo, // Preserve existing fields
              profilePhotoUrl: newImageUrl, // Update profile photo URL
            },
          });
        } else {
          login({
            ...user,
            primaryInfo: {
              ...user.primaryInfo, // Preserve existing fields
              coverPhotoUrl: newImageUrl, // Update cover photo URL
            },
          });
        }

        // Notify success
        toast.success(`${photoType.toUpperCase()} image updated successfully!`);
        onSave(photoType, newImageUrl); // Notify parent of the update
      } else {
        throw new Error('Failed to upload image.');
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to update image.');
      setError('Failed to save. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelClick = () => {
    setSelectedFile(null);
    setPreviewUrl(existingPhotoUrl);
    setError('');
    onCancel();
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-6 rounded-lg">
      <Toaster position="top-right" />
      <h2 className="text-lg font-bold text-emerald-700 mb-4">
        {photoType === 'cover' ? 'Update Cover Photo' : 'Update Profile Photo'}
      </h2>

      {/* DRAG & DROP ZONE */}
      <div
        ref={dropzoneRef}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`
          w-full h-40 flex items-center justify-center rounded-lg border-2 border-dashed cursor-pointer 
          transition-colors 
          ${isDragging ? 'border-emerald-500 bg-emerald-50' : 'border-emerald-300 bg-white'}
        `}
      >
        {!previewUrl ? (
          <div className="text-center text-gray-500">
            <FaCloudUploadAlt size={32} className="mx-auto mb-2" />
            <p className="text-sm">Drag & drop or click below to select a file</p>
          </div>
        ) : (
          <img
            src={previewUrl}
            alt="Preview"
            className="h-full w-full object-cover rounded"
          />
        )}
      </div>

      {/* Compression Progress */}
      {compressionProgress > 0 && (
        <p className="text-xs text-gray-600 mt-2">
          Compressing... {compressionProgress.toFixed(2)}%
        </p>
      )}

      {/* SELECT FILE BUTTON */}
      <div className="mt-4">
        <label className="flex items-center justify-center gap-2 px-4 py-2 rounded border border-emerald-500 text-emerald-500 cursor-pointer hover:bg-emerald-50 hover:border-emerald-700 hover:text-emerald-700">
          <FaCloudUploadAlt size={16} /> Select File
          <input
            type="file"
            accept=".jpeg,.png,.jpg"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
      </div>

      {/* ERROR MESSAGE */}
      {error && <p className="text-red-600 text-sm mt-2">{error}</p>}

      {/* INFORMATIONAL TEXT */}
      <div className="w-full mt-6">
        <div className="flex items-center text-xs text-gray-500">
          <FaLightbulb className="text-yellow-400 mr-2" />
          <p>Tip: Upload high-quality images. Files are compressed for faster upload.</p>
        </div>
        <hr className="my-4 border-gray-300" />
      </div>

      {/* ACTION BUTTONS */}
      <div className="flex gap-4 mt-4">
        <button
          onClick={handleSaveClick}
          disabled={isSaving}
          className="px-6 py-2 rounded border border-emerald-500 text-emerald-500 hover:bg-emerald-50 hover:border-emerald-700 hover:text-emerald-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
        >
          {isSaving ? (
            <>
              <FaSpinner className="animate-spin mr-2" /> Saving...
            </>
          ) : (
            'Save'
          )}
        </button>
        <button
          onClick={handleCancelClick}
          disabled={isSaving}
          className="px-6 py-2 rounded border border-gray-500 text-gray-500 hover:bg-gray-100 hover:border-gray-700 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ProfilePhotoUploader;

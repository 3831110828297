// File: HelpArticleDisplay.js
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // For SEO
import { FaThumbsUp, FaThumbsDown, FaShareSquare } from 'react-icons/fa';
import api from '../../api';

function HelpArticleDisplay() {
  const { slug } = useParams(); // Get the slug from URL
  const navigate = useNavigate();

  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [feedback, setFeedback] = useState(null); // 'like' or 'dislike' or null

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/api/help/articles/slug/${slug}`); // Fetch article by slug
        setArticle(data.article);

        // Fetch related articles if the article and category exist
        if (data.article?.category) {
          fetchRelatedArticles(data.article.category._id, data.article.slug);
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        setError('Failed to load this article. It may have been removed or is unavailable.');
      } finally {
        setLoading(false);
      }
    };

    const fetchRelatedArticles = async (categoryId, currentSlug) => {
      try {
        const { data } = await api.get(`/api/help/articles?category=${categoryId}`);
        if (data?.articles) {
          const filtered = data.articles.filter((a) => a.slug !== currentSlug); // Exclude current article
          setRelatedArticles(filtered);
        }
      } catch (err) {
        console.error('Error fetching related articles:', err);
        setRelatedArticles([]); // Fallback to an empty array if fetching fails
      }
    };

    fetchArticle();
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-white to-green-50 flex justify-center items-center">
        <div className="w-12 h-12 border-4 border-green-300 border-dashed rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-white to-green-50 flex flex-col justify-center items-center p-4">
        <p className="text-red-600 text-center mb-4">{error}</p>
        <Link to="/help" className="text-green-700 hover:underline font-semibold">
          &larr; Back to Help Center
        </Link>
      </div>
    );
  }

  if (!article) {
    return null;
  }

  const pageTitle = `${article.title} | Help Center`;
  const metaDescription = article.summary || `Read more about ${article.title}`;
  const metaImage = article.imageUrl || '/default-article-thumbnail.png'; // Fallback image
  const pageUrl = `${window.location.origin}/help/article/${article.slug}`;
  
  const publishedDate = article.publishedAt
  ? new Date(article.publishedAt).toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    })
  : null;


  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({ title: article.title, url: pageUrl });
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      try {
        await navigator.clipboard.writeText(pageUrl);
        alert('Link copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy link:', err);
      }
    }
  };

  const handleLike = () => setFeedback('like');
  const handleDislike = () => setFeedback('dislike');

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-green-50">
      <Helmet>
        {/* Dynamic SEO Meta Tags */}
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={pageUrl} />

        {/* Open Graph Tags for Social Media */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:url" content={pageUrl} />

        {/* Twitter Card Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        <meta name="twitter:url" content={pageUrl} />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            headline: article.title,
            description: article.summary,
            image: metaImage,
            datePublished: article.publishedAt,
            author: {
              '@type': 'Person',
              name: `${article.publisher.firstName} ${article.publisher.lastName}`,
            },
          })}
        </script>
      </Helmet>

      {/* Article Content */}
      <div className="max-w-3xl mx-auto px-4 md:px-6 lg:px-8 py-8">
        {/* Article Heading */}
        <div className="mb-6">
          <h1 className="text-4xl font-extrabold text-gray-800 mb-4">{article.title}</h1>
          <p className="text-sm text-gray-500 italic">{article.summary}</p>
          <div className="mt-3 flex items-center space-x-3 text-xs text-gray-500">
            {article.publisher && (
              <div className="flex items-center space-x-2">
                {article.publisher.profilePhoto && (
                  <img
                    src={article.publisher.profilePhoto}
                    alt="Publisher"
                    className="w-8 h-8 rounded-full object-cover border border-gray-300"
                  />
                )}
                <span>
                  By {article.publisher.firstName} {article.publisher.lastName}
                </span>
              </div>
            )}
            {article.publishedAt && <span className="whitespace-nowrap">on {publishedDate}</span>}
          </div>
        </div>

        {/* Thumbnail */}
        {article.imageUrl && (
          <div className="mb-6">
            <img
              src={article.imageUrl}
              alt={article.title}
              className="w-full max-h-96 object-cover rounded-md shadow"
            />
          </div>
        )}

        {/* Article Content */}
        <div className="prose lg:prose-xl max-w-none text-gray-800">
          <div dangerouslySetInnerHTML={{ __html: article.content }} />
        </div>

        {/* Feedback Section */}
        <div className="mt-8 flex items-center space-x-6">
          <div className="flex items-center space-x-2">
            <button
              onClick={handleLike}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                feedback === 'like' ? 'bg-green-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              <FaThumbsUp className="mr-2" />
              Like
            </button>
            <button
              onClick={handleDislike}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                feedback === 'dislike' ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              <FaThumbsDown className="mr-2" />
              Dislike
            </button>
          </div>
          <button
            onClick={handleShare}
            className="flex items-center bg-blue-600 text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-blue-700"
          >
            <FaShareSquare className="mr-2" />
            Share
          </button>
        </div>

        {/* Related Articles Section */}
        {relatedArticles.length > 0 && (
          <div className="mt-12">
            <h2 className="text-xl font-bold text-gray-700 mb-4">Related Articles</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {relatedArticles.map((rel) => (
                <div
                  key={rel.slug}
                  onClick={() => navigate(`/help/article/${rel.slug}`)} // Navigate using slug
                  className="border border-gray-200 bg-white p-4 rounded-md shadow hover:shadow-lg transition cursor-pointer"
                >
                  <h3 className="text-lg font-semibold text-gray-800">{rel.title}</h3>
                  <p className="text-xs text-gray-600 italic">{rel.summary}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mt-8">
          <Link to="/help" className="text-green-700 hover:underline font-semibold">
            &larr; Back to Help Center
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HelpArticleDisplay;

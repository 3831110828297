import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  FaPiggyBank,
  FaHistory,
  FaLock,
  FaInfoCircle
} from 'react-icons/fa';

import Deposit from './Deposit';
import DepositHistory from './DepositHistory';
import DepositDetail from './DepositDetail';

const DepositContainer = ({ setActiveComponent }) => {
  const [activeTab, setActiveTab] = useState('deposit');
  const [selectedDepositId, setSelectedDepositId] = useState(null);

  // Define tabs for side nav & mobile dropdown
  const tabOptions = [
    { label: 'Deposit Funds', value: 'deposit', icon: <FaPiggyBank /> },
    { label: 'Deposit History', value: 'history', icon: <FaHistory /> },
  ];

  // Render the correct component
  const renderContent = () => {
    if (activeTab === 'detail' && selectedDepositId) {
      return (
        <DepositDetail
          depositId={selectedDepositId}
          onBack={() => {
            setActiveTab('history');
            setSelectedDepositId(null);
          }}
        />
      );
    }
    switch (activeTab) {
      case 'deposit':
        return <Deposit setActiveComponent={setActiveComponent} />;
      case 'history':
        return <DepositHistory onViewDetails={handleViewDetails} />;
      default:
        return <Deposit setActiveComponent={setActiveComponent} />;
    }
  };

  // "View Details" in deposit history
  const handleViewDetails = (depositId) => {
    setSelectedDepositId(depositId);
    setActiveTab('detail');
  };

  return (
    <div
      className="
        w-full
        bg-gradient-to-br from-white via-emerald-50 to-white
        py-8
        px-2 sm:px-4 md:px-6 lg:px-8
        lg:max-w-7xl
        mx-auto
        rounded-2xl
        shadow-xl
        border
        border-emerald-100
      "
    >
      {/* Title / Headline */}
      <div className="text-center mb-6">
    
      </div>

      {/* Layout: side nav (desktop) | dropdown (mobile) + content */}
      <div className="flex flex-col md:flex-row md:space-x-6">
        
        {/* Left Column: Tab Navigation (desktop only) */}
        <div className="hidden md:flex flex-col md:w-1/3 lg:w-1/4 space-y-2 border-r pr-4">
          {tabOptions.map((tab) => (
            <button
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
              className={`
                flex items-center
                w-full
                px-4 py-3
                text-left
                font-semibold
                rounded-r-full
                transition-all
                ${
                  activeTab === tab.value
                    ? 'bg-emerald-100 text-emerald-700 border-l-4 border-emerald-500'
                    : 'text-gray-600 hover:bg-gray-50'
                }
              `}
            >
              <span className="mr-2 text-xl">{tab.icon}</span>
              {tab.label}
            </button>
          ))}

          {/* Large-screen-only Ad Image */}
          <div className="hidden lg:block mt-10">
            <img
              src="https://d30sleee6j32ev.cloudfront.net/Withdrawals/images/withdrawal_placeholders/deposit_picture.png"
              alt="Promotional Ad"
              className="rounded-xl  w-full"
            />
        
          </div>
        </div>

        {/* Mobile: dropdown for tabs (when not on detail screen) */}
        {activeTab !== 'detail' && (
          <div className="md:hidden mb-4 border-b">
            <Menu as="div" className="relative">
              <Menu.Button
                className="
                  flex justify-between
                  w-full
                  px-4 py-2
                  border
                  rounded
                  text-sm font-medium
                  text-gray-700
                  hover:bg-emerald-50
                  focus:outline-none
                "
              >
                {
                  tabOptions.find((t) => t.value === activeTab)?.label ||
                  'Select Deposit Tab'
                }
                <ChevronDownIcon
                  className="w-5 h-5 ml-2 text-emerald-500 animate-bounce"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Menu.Items
                className="
                  absolute
                  w-full
                  mt-1
                  bg-white
                  border
                  rounded
                  shadow-lg
                  z-10
                "
              >
                {tabOptions.map((tab) => (
                  <Menu.Item key={tab.value}>
                    {({ active }) => (
                      <button
                        onClick={() => setActiveTab(tab.value)}
                        className={`
                          w-full
                          text-left
                          px-4 py-2
                          text-sm
                          text-gray-700
                          flex items-center
                          ${active ? 'bg-emerald-100' : ''}
                        `}
                      >
                        <span className="mr-2 text-lg">{tab.icon}</span>
                        {tab.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </div>
        )}

        {/* Right Column: main content */}
        <div className="md:w-2/3 lg:w-3/4">
          {renderContent()}

          {/* Footnotes / Support */}
          <div
            className="
              mt-6
              p-4
              border-t
              text-xs
              text-gray-600
              text-center
              rounded
              bg-gray-100
            "
          >
            <div className="flex justify-center items-center mt-2">
              <FaLock className="text-green-600 mr-2" />
              <span>Payment is secured with DLocal</span>
            </div>
            <p className="mt-2">Ravel Global Pay, Apt. 992</p>
            <p>54072 Larson Stravenue, Port Kymside, IA 70661-2925</p>
            <p className="mt-2">
              For support:{' '}
              <a
                href="mailto:support@verdantcharity.org"
                className="text-blue-600 hover:text-blue-800"
              >
                support@verdantcharity.org
              </a>{' '}
              | Hotline: +1 385 233 9891
            </p>
            <div className="flex items-center justify-center mt-4 text-gray-400">
              <FaInfoCircle className="mr-1" />
              <p>All deposits are subject to Terms & Conditions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositContainer;

/**
 * TradeOffer.js
 * The "Offer" tab content, refactored into its own component.
 */

import React from 'react';
import {
  FaSpinner,
  FaShieldAlt,
  FaRegClock,
  FaRegStar,
  FaCheckCircle,
  FaThumbsUp,
  FaThumbsDown,
  FaInfoCircle
} from 'react-icons/fa';
import {
  HiOutlineExclamationCircle,
  HiOutlineChevronDown,
  HiOutlineChevronUp
} from 'react-icons/hi';

const TradeOffer = ({
  points,
  setPoints,
  loading,
  loadingExchangeRate,
  exchangeRate,
  exchangeRateError,
  handleBuyNowClick,
  navigate
}) => {

  // Handler for points input
  const handlePointsChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setPoints(value);
    }
  };

  return (
    <>
      {/* Confidence Alert */}
      <div className="max-w-4xl w-full mt-2 p-2 bg-blue-50 border border-blue-300 text-blue-700 text-sm rounded shadow-sm">
        <p className="flex items-center">
          <FaShieldAlt className="mr-1 text-blue-500" />
          This platform uses secure escrow for every trade. Your funds and points
          remain safe until all conditions are met.
        </p>
      </div>

      {/* Offer Card */}
      <div className="max-w-4xl w-full p-4 bg-white rounded-lg border border-gray-300 mt-4">
        {/* Heading */}
        <div className="bg-emerald-300 text-white text-sm font-bold p-4 rounded-t-lg">
          How many points do you want to Buy?
        </div>

        {/* Form Row */}
        <div className="flex flex-col md:flex-row md:justify-between p-4 gap-4">
          {/* Points Input */}
          <div className="flex flex-col w-full md:w-1/2">
            <label htmlFor="points" className="text-xs font-semibold mb-2">
              I want
            </label>
            <div className="relative">
              <input
                type="text"
                id="points"
                placeholder="points"
                className={`p-2 pl-2 pr-10 border rounded text-xs sm:text-xs md:text-sm focus:outline-none 
                ${
                  parseInt(points, 10) < 50 && points !== ''
                    ? 'border-red-500'
                    : 'focus:ring-emerald-500 focus:border-emerald-500'
                } w-full`}
                value={points}
                onChange={handlePointsChange}
              />
              <span className="absolute inset-y-0 right-2 flex items-center text-gray-500 text-xs pointer-events-none">
                points
              </span>
            </div>
            {parseInt(points, 10) < 50 && points !== '' ? (
              <p className="text-red-500 text-xs mt-2">
                You must enter at least 50 points.
              </p>
            ) : (
              <p className="text-gray-500 text-xs mt-2">
                Enter points to get started
              </p>
            )}
          </div>

          {/* Amount (KES) */}
          <div className="flex flex-col w-full md:w-1/2">
            <label
              htmlFor="amountToPay"
              className="text-xs font-semibold mb-2"
            >
              I will pay
            </label>
            <div className="relative">
              <input
                type="text"
                id="amountToPay"
                placeholder="Amount"
                readOnly
                className="p-2 pl-2 pr-10 border rounded text-xs sm:text-xs md:text-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 w-full"
                value={(points * exchangeRate).toFixed(2)}
              />
              <span className="absolute inset-y-0 right-2 flex items-center text-gray-500 text-xs pointer-events-none">
                KES
              </span>
            </div>
            {loadingExchangeRate && (
              <span className="text-xs text-green-500">
                <FaSpinner className="animate-spin inline-block mr-1" />
                Loading Conversion...
              </span>
            )}
            {exchangeRateError && (
              <span className="text-xs text-red-500">
                Failed to load conversion rate. Please try again later.
              </span>
            )}
          </div>
        </div>

        {/* Additional Options */}
        <div className="flex flex-col md:flex-row md:justify-between p-4 gap-4">
          <div className="flex flex-col w-full">
            <label
              htmlFor="tradePartnerBank"
              className="text-xs font-semibold mb-2 flex items-center"
            >
              Trade Partner’s Bank
              <HiOutlineExclamationCircle className="ml-1 text-sm" />
            </label>
            <select
              id="tradePartnerBank"
              className="p-2 border rounded text-xs sm:text-xs md:text-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
            >
              <option value="None">Not specified</option>
              <option value="BankOne">BankOne</option>
              <option value="BankTwo">BankTwo</option>
              {/* Add more bank options here if needed */}
            </select>
          </div>
        </div>

        {/* Buttons & Security Note */}
        <div className="flex flex-col p-4 gap-4">
          <div className="flex flex-col md:flex-row w-full gap-2 md:gap-4 mb-4">
            <button
              onClick={handleBuyNowClick}
              disabled={
                loading ||
                loadingExchangeRate ||
                exchangeRateError ||
                !points ||
                points < 50
              }
              className={`
                w-full sm:w-auto
                border border-emerald-500
                text-emerald-500
                hover:bg-emerald-500
                hover:text-white
                py-2 px-4
                rounded
                transition duration-300
                ${loading || loadingExchangeRate ? 'bg-emerald-500 text-white' : ''}
              `}
            >
              <div className="flex items-center justify-center">
                {(loading || loadingExchangeRate) && (
                  <FaSpinner className="animate-spin mr-2" />
                )}
                {loading
                  ? 'Starting Trade...'
                  : loadingExchangeRate
                  ? 'Loading...'
                  : 'Start Trade'}
              </div>
            </button>

            <button
              className="border border-gray-300 text-gray-600 hover:bg-gray-300 hover:text-white py-2 px-4 rounded"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
          <div className="border-t border-gray-300 pt-4">
            <p className="flex items-center justify-center text-gray-500 text-xs">
              <FaShieldAlt className="mr-1" />
              Secure escrow + Automated trade with Lucy
            </p>
          </div>
        </div>
      </div>

      {/* Advertisement (Mobile-only) */}
      <img
        src="https://res.cloudinary.com/dx6jw8k0m/image/upload/v1723665576/Orange_and_Purple_Modern_Tips_Grow_Business_Linkedin_Article_Cover_Image_1_rqufoe.gif"
        alt="Verdant Charity Ad"
        className="lg:hidden w-full h-auto my-6 rounded-lg shadow-lg border border-gray-200"
        style={{ objectFit: 'contain' }}
      />

      {/* Offer Details */}
      <div className="max-w-4xl w-full bg-white p-4 border border-gray-300 my-4 rounded-lg">
        <div className="grid md:grid-cols-2 gap-4">
          {/* About offer */}
          <div className="border border-gray-300 rounded-lg p-4 space-y-4">
            <h3 className="font-semibold text-emerald-600 mb-2">
              About this offer
            </h3>
            <div className="flex items-center text-xs font-semibold space-x-1">
              <FaRegStar className="text-yellow-500" />
              <span>Seller rate:</span>
              <span className="text-sm ml-1 flex items-center">
                2% <HiOutlineChevronDown className="text-red-500" />
              </span>
            </div>
            <div className="text-xs font-semibold flex items-center space-x-1">
              <HiOutlineChevronDown className="text-green-500" />
              <span>Min:</span>
              <span>50 points</span>
            </div>
            <div className="text-xs font-semibold flex items-center space-x-1">
              <HiOutlineChevronUp className="text-red-500" />
              <span>Max:</span>
              <span>1000 points</span>
            </div>
            <div className="flex items-center text-xs font-semibold space-x-1">
              <FaRegClock className="text-gray-500" />
              <span>Trade time limit:</span>
              <span>30 min</span>
            </div>
          </div>

          {/* About seller */}
          <div className="bg-white border border-gray-300 rounded-lg p-4">
            <h3 className="font-semibold text-emerald-600 mb-2">
              About this seller
            </h3>
            <div className="flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <img
                  src="/Lucy-Kiarie.jpg"
                  alt="Seller"
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex flex-col justify-between">
                  <span className="font-semibold">Lucy Kiarie</span>
                  <div className="flex items-center text-sm text-gray-400">
                    <img
                      src="https://d1g0iq4cbcvjcd.cloudfront.net/topics/images/user-circle-check.svg"
                      alt="Verified"
                      className="w-3 h-3 mr-1"
                    />
                    Verified
                  </div>
                  <span className="flex items-center text-green-500 text-xs">
                    <FaRegClock className="mr-1" />
                    <span className="animate-pulse">online</span>
                  </span>
                </div>
              </div>
            </div>

            {/* Thumbs up/down */}
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center text-green-800 text-xs font-semibold mr-2 bg-green-50 border border-green-200 rounded-md py-1 px-2">
                <FaThumbsUp className="text-green-500" />
                <span className="ml-1">3210</span>
              </div>
              <div className="flex items-center text-red-800 text-xs font-semibold bg-red-100 border border-red-200 rounded-md py-1 px-2">
                <FaThumbsDown className="text-red-500" />
                <span className="ml-1">23</span>
              </div>
            </div>

            {/* Verification checks */}
            <div className="flex flex-col mt-2 space-y-1 lg:grid lg:grid-cols-2 lg:gap-4">
              <div className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-1" size="0.75em" />
                <span>ID verified</span>
              </div>
              <div className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-1" size="0.75em" />
                <span>Email verified</span>
              </div>
              <div className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-1" size="0.75em" />
                <span>Phone verified</span>
              </div>
              <div className="flex items-center">
                <FaCheckCircle className="text-green-500 mr-1" size="0.75em" />
                <span>Address verified</span>
              </div>
            </div>

            {/* Trade speed */}
            <div className="mt-4 pt-4 border-t border-gray-300">
              <div className="text-xs font-semibold flex flex-col">
                <div className="flex items-center">
                  <span className="text-gray-600">Average trade speed</span>
                  <FaInfoCircle className="text-gray-400 ml-1" />
                </div>
                <div className="mt-3">
                  <span className="text-xs font-semibold bg-green-50 border border-green-400 text-black-400 py-1 px-3 rounded-sm">
                    Instant
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Offer terms */}
        <div className="bg-white border border-gray-300 rounded-lg p-4 mt-4 lg:mt-6">
          <h3 className="text-emerald-600 font-semibold mb-3 text-sm lg:text-base">
            Offer terms
          </h3>
          <ul className="list-disc text-sm pl-6 lg:pl-8 space-y-2">
            <li>
              Before a trade starts, the seller selects the bank account
              they'll receive payment to, and the buyer selects the account
              they will send funds from.
            </li>
            <li>
              When the seller is ready to start the trade, they'll share their
              bank account details with the buyer.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TradeOffer;

/**
 * TradeAbout.js
 * The "About" tab content
 * Enhanced with multiple sections, images (dummy URLs), and user-provided text about safety & KYC.
 */
import React from 'react';
import { FaShieldAlt } from 'react-icons/fa';

const TradeAbout = () => {
  return (
    <div className="max-w-4xl w-full bg-white p-4 border border-gray-300 my-4 rounded-lg space-y-6">
      {/* Confidence Alert */}
      <div className="p-3 bg-blue-50 border border-blue-200 text-blue-700 text-sm rounded">
        <p>Your security is our top priority. All transactions are held in secure escrow until both parties are satisfied.</p>
      </div>

      {/* Main Heading */}
      <h2 className="text-emerald-600 font-bold text-lg">About Us</h2>
      <p className="text-sm text-gray-600">
        At Verdant Charity, we’re building a marketplace where you can trade points securely and confidently. Our platform is powered by a dedicated team of professionals who ensure swift transactions and robust user protections.
      </p>

      {/* Insert an example image from Ouch or any site */}
      <div className="flex justify-center">
        <img
          src="https://d30sleee6j32ev.cloudfront.net/Withdrawals/images/withdrawal_placeholders/security.png" 
          alt="Security illustration"
          className="w-48 h-auto my-4"
        />
      </div>

      {/* Our Mission */}
      <div className="p-3 bg-gray-50 border border-gray-300 rounded text-sm text-gray-700">
        <p>
          <FaShieldAlt className="inline-block mr-1 text-emerald-500" />
          <strong>Our Mission:</strong> To provide a reliable marketplace for point transactions worldwide, prioritizing user safety and convenience.
        </p>
      </div>

      {/* KYC Verification */}
      <section className="space-y-2 text-sm">
        <h3 className="text-md font-semibold text-emerald-600">Comprehensive KYC Verification Process</h3>
        <p className="text-gray-700">
          Each seller undergoes a thorough verification process conducted by real people, not just automated systems. This includes:
        </p>
        <ul className="list-disc ml-5 text-gray-700">
          <li><strong>Personal Identification</strong>: Verification of government-issued IDs to confirm the identity of the seller.</li>
          <li><strong>Contact Information</strong>: Confirmation of up-to-date contact details for reliable communication.</li>
          <li><strong>Payment Methods</strong>: Verification of legitimate and secure payment methods to facilitate risk-free transactions.</li>
        </ul>
        <p className="text-gray-700">
          By conducting these checks, we aim to create a secure and trustworthy environment where all members can feel safe while engaging in transactions.
        </p>

        {/* Second example image */}
        <div className="flex justify-center">
          <img
            src="https://d30sleee6j32ev.cloudfront.net/Withdrawals/images/withdrawal_placeholders/files.png"
            alt="KYC process illustration"
            className="w-40 h-auto my-4 "
          />
        </div>
      </section>

      {/* Why Human-Led Verification Matters */}
      <section className="space-y-2 text-sm">
        <h3 className="text-md font-semibold text-emerald-600">Why Human-Led Verification Matters</h3>
        <p className="text-gray-700">
          Automated systems can catch discrepancies, but human oversight ensures subtle nuances and complex issues are addressed. This human touch is essential in building a trustworthy platform.
        </p>
        <p className="text-gray-700 italic">
          <strong>Example:</strong> When a new seller, Lisa, joins the marketplace, she submits her identification documents and other necessary info. Our team reviews all materials to confirm validity and completeness. Only after Lisa passes this rigorous check is she allowed to list her impact points for sale.
        </p>
      </section>

      {/* Trust as a Cornerstone */}
      <section className="space-y-2 text-sm">
        <h3 className="text-md font-semibold text-emerald-600">Trust as a Cornerstone</h3>
        <p className="text-gray-700">
          We employ multiple strategies to ensure all sellers are reliable, thus safeguarding the interests of our community members.
        </p>
        <ul className="list-disc ml-5 text-gray-700">
          <li><strong>Strict KYC Verification:</strong> Identity, address proof, and phone verification.</li>
          <li><strong>Security Deposits:</strong> A deposit assures financial coverage in rare cases of fraud.</li>
          <li><strong>Seller Ratings and Reviews:</strong> Transparent feedback loop for accountability.</li>
          <li><strong>Escrow Service:</strong> Funds are only released once terms are satisfactorily met.</li>
          <li><strong>Ongoing Monitoring:</strong> Suspicious activity triggers immediate investigation.</li>
          <li><strong>Community Feedback:</strong> User reports influence a seller’s standing and foster a self-regulating environment.</li>
        </ul>
      </section>

      {/* Another example image */}
      <div className="flex justify-center">
        <img
          src="https://d30sleee6j32ev.cloudfront.net/Withdrawals/images/withdrawal_placeholders/trust_building.png"
          alt="Trust building illustration"
          className="w-48 h-auto my-4 "
        />
      </div>

      {/* Conclusion */}
      <section className="space-y-2 text-sm">
        <p className="text-gray-700">
          These multi-layered safeguards give you confidence when trading with sellers like Maria, who has a verified profile, a security deposit, and positive reviews. 
        </p>
        <p className="text-gray-700">
          By maintaining these high standards, Verdant Charity not only protects its members but also fosters a community where trust is the foundation of every transaction.
        </p>
      </section>
    </div>
  );
};

export default TradeAbout;

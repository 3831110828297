// CommunityUserPosts.js
import React from 'react';

const CommunityUserPosts = () => {
  return (
    <div className="grid grid-cols-3 gap-2 sm:gap-4">
      {/* 6 dummy images */}
      {Array(6).fill(0).map((_, idx) => (
        <div key={idx} className="bg-gray-200 aspect-w-1 aspect-h-1">
          <img
            src={`https://source.unsplash.com/random/300x300?sig=${idx}&nature`}
            alt="post"
            className="object-cover w-full h-full"
          />
        </div>
      ))}
    </div>
  );
};

export default CommunityUserPosts;

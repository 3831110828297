import React, { useState } from 'react';
import {
  FaArrowLeft,
  FaCheckCircle,
  FaHandsHelping,
  FaGlobe,
  FaCalendarAlt,
  FaHeart,
  FaFlag,
} from 'react-icons/fa';
import CommunityUserPosts from './CommunityUserPosts';
import CommunityUserCampaigns from './CommunityUserCampaigns';

function getRandomCoverImage() {
  const randomId = Math.floor(Math.random() * 1000);
  return `https://source.unsplash.com/1080x600/?charity,help,${randomId}`;
}

function getRandomJoinedDate() {
  const start = new Date(2015, 0, 1);
  const end = new Date();
  const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

const CommunityUserDetail = ({ user, onBack }) => {
  const [activeTab, setActiveTab] = useState('about');

  const coverImageUrl = user.coverImageUrl || getRandomCoverImage();
  const profileImageUrl =
    user.logoUrl || `https://randomuser.me/api/portraits/lego/5.jpg`;
  const joinedDate = user.joinedDate || getRandomJoinedDate();
  const location = user.location || 'Global';

  const hasPosts = user.posts && user.posts.length > 0;
  const hasCampaigns = user.campaigns && user.campaigns.length > 0;

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-white px-2 py-4">
      <div className="max-w-3xl mx-auto bg-white  rounded-lg overflow-hidden relative">
        {/* Back Button */}
        <button
          onClick={onBack}
          className="absolute top-2 left-2 z-10 bg-white p-2 rounded-full shadow hover:shadow-lg"
        >
          <FaArrowLeft className="text-gray-800" />
        </button>

        {/* Cover Image */}
        <div className="h-48 bg-gray-100 relative">
          <img src={coverImageUrl} alt="cover" className="object-cover w-full h-full" />
          {/* Profile Picture */}
          <div className="absolute -bottom-12 left-1/2 transform -translate-x-1/2">
            <img
              src={profileImageUrl}
              alt={user.name}
              className="w-24 h-24 sm:w-28 sm:h-28 rounded-full border-4 border-white object-cover"
            />
          </div>
        </div>

        {/* User Info */}
        <div className="mt-16 px-4 text-center">
          <h2 className="text-lg sm:text-xl font-bold text-gray-800 flex items-center justify-center">
            {user.name}
            {user.verified && (
              <FaCheckCircle className="text-emerald-500 ml-2" title="Verified" />
            )}
          </h2>
          <p className="text-gray-500 text-sm flex items-center justify-center">
            <FaGlobe className="mr-1" />
            {location}
          </p>
          <p className="text-gray-500 text-sm flex items-center justify-center mt-1">
            <FaCalendarAlt className="mr-1" />
            Joined {joinedDate}
          </p>

          {/* Stats */}
          <div className="flex justify-center space-x-8 my-4">
            <div>
              <p className="text-lg font-semibold text-gray-800">{user.followersCount || 0}</p>
              <p className="text-gray-500 text-xs">Followers</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-800">{user.supporting || 0}</p>
              <p className="text-gray-500 text-xs">Supporting</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-800">{user.endorsementsCount || 0}</p>
              <p className="text-gray-500 text-xs">Endorsements</p>
            </div>
          </div>
        </div>

        {/* Tab Navigation */}
        <div className="border-t border-gray-200 flex justify-around text-center text-sm">
          <button
            onClick={() => setActiveTab('about')}
            className={`flex-1 py-3 ${
              activeTab === 'about'
                ? 'text-emerald-600 border-b-2 border-emerald-500'
                : 'text-gray-400'
            }`}
          >
            <FaHandsHelping className="mx-auto mb-1" />
            About
          </button>
          <button
            onClick={() => setActiveTab('posts')}
            className={`flex-1 py-3 ${
              activeTab === 'posts'
                ? 'text-emerald-600 border-b-2 border-emerald-500'
                : 'text-gray-400'
            }`}
          >
            <FaHeart className="mx-auto mb-1" />
            Posts
          </button>
          <button
            onClick={() => setActiveTab('campaigns')}
            className={`flex-1 py-3 ${
              activeTab === 'campaigns'
                ? 'text-emerald-600 border-b-2 border-emerald-500'
                : 'text-gray-400'
            }`}
          >
            <FaFlag className="mx-auto mb-1" />
            Campaigns
          </button>
        </div>

        {/* Tab Content */}
        <div className="p-4">
          {activeTab === 'about' && (
            <div className="text-gray-700 text-sm space-y-4">
              <p>{user.description || 'No mission details provided.'}</p>
              {user.vision && (
                <div>
                  <h3 className="font-bold text-gray-800">Vision</h3>
                  <p>{user.vision}</p>
                </div>
              )}
              {user.message && (
                <div>
                  <h3 className="font-bold text-gray-800">Message</h3>
                  <p>{user.message}</p>
                </div>
              )}
              {user.objectives && user.objectives.length > 0 && (
                <div>
                  <h3 className="font-bold text-gray-800">Objectives</h3>
                  <ul className="list-disc pl-5">
                    {user.objectives.map((objective, index) => (
                      <li key={index}>{objective}</li>
                    ))}
                  </ul>
                </div>
              )}
              {user.coreValues && (
                <div>
                  <h3 className="font-bold text-gray-800">Core Values</h3>
                  <p>{user.coreValues}</p>
                </div>
              )}
            </div>
          )}

          {activeTab === 'posts' && (
            <>
              {hasPosts ? (
                <CommunityUserPosts posts={user.posts} />
              ) : (
                <p className="text-center text-gray-500">This user's posts are private.</p>
              )}
            </>
          )}

          {activeTab === 'campaigns' && (
            <>
              {hasCampaigns ? (
                <CommunityUserCampaigns campaigns={user.campaigns} />
              ) : (
                <p className="text-center text-gray-500">This user's campaigns are private.</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityUserDetail;

// CommunityUserCampaigns.js
import React from 'react';

const CommunityUserCampaigns = () => {
  return (
    <div className="space-y-4">
      {[1,2].map((campId) => (
        <div key={campId} className="bg-white shadow rounded-md overflow-hidden">
          <img
            src={`https://source.unsplash.com/random/600x400?charity&sig=${campId}`}
            alt="campaign"
            className="w-full h-32 object-cover"
          />
          <div className="p-3">
            <h4 className="text-sm font-semibold">Campaign #{campId}</h4>
            <p className="text-xs text-gray-600">Sample campaign description...</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommunityUserCampaigns;

import React, { useState } from 'react';
import Confetti from 'react-confetti';
import Lottie from 'lottie-react';
import successAnimation from "../../lottie/success-animation.json";
import successConfetti from '../../lottie/success-confetti.json';
import { FaSpinner, FaCheck, FaHistory, FaShieldAlt } from 'react-icons/fa';
import { HiOutlineDownload } from "react-icons/hi";
import api from '../../../api';
import { useUser } from "../../context";
import { useNavigate } from 'react-router-dom';

const PaypalWithdrawal = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    const [withdrawDetails, setWithdrawDetails] = useState({
        amount: '',
        email: '',
        currency: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [selectedCurrencyBalance, setSelectedCurrencyBalance] = useState(0);
    const [inputError, setInputError] = useState('');

    const currencies = user?.accounts?.map(account => ({
        currency: account.currency,
        balance: account.balance
    })) || [];

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedDetails = { ...withdrawDetails, [name]: value };
        setWithdrawDetails(updatedDetails);
        setError('');

        if (name === 'currency') {
            // Find selected currency's balance
            const account = currencies.find(c => c.currency === value);
            setSelectedCurrencyBalance(account ? account.balance : 0);
            // Reset amount and input error
            setWithdrawDetails({ ...updatedDetails, amount: '' });
            setInputError('');
        } else if (name === 'amount') {
            if (!withdrawDetails.currency) {
                setInputError('Please select a currency first');
                return;
            }
            const account = currencies.find(
                (c) => c.currency === withdrawDetails.currency
            );
            const balance = account ? account.balance : 0;
            if (parseFloat(value) > balance) {
                setInputError(
                    `Insufficient balance in ${withdrawDetails.currency}.`
                );
            } else {
                setInputError('');
            }
        }
    };

    // Submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');

        try {
            const response = await api.post(
                '/api/transactions/withdraw/paypal',
                withdrawDetails,
                { headers: { Authorization: `Bearer ${user.token}` } }
            );
            if (response.status === 201) {
                setSuccess(true);
                setShowConfetti(true);
                setTimeout(() => setShowConfetti(false), 10000);
            } else {
                setError("Failed to process withdrawal. Please try again.");
            }
        } catch (err) {
            setError(
                err.response?.data?.message || "An error occurred during withdrawal."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md relative border-4 border-emerald-100">
            {/* Confetti */}
            {showConfetti && <Confetti />}

            {/* Heading & Subheading */}
            <div className="text-center mb-6">
                <h2 className="text-xl font-extrabold text-emerald-600 mb-2">
                    PayPal Withdrawal
                </h2>
                <p className="text-gray-500 text-sm flex items-center justify-center">
                    <FaShieldAlt className="text-emerald-600 mr-1" /> Securely cash out your balance
                </p>

            </div>

            {success ? (
                <div className="flex flex-col items-center justify-center w-full p-4">
                    {/* Success Animations */}
                    <div className="relative w-full h-48 md:h-64">
                        <Lottie
                            animationData={successConfetti}
                            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                        />
                        <Lottie
                            animationData={successAnimation}
                            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                        />
                    </div>
                    <p className="text-lg font-semibold text-emerald-700 mt-4 text-center">
                        Withdrawal Successful!
                    </p>
                    <div className="mt-4 flex flex-col sm:flex-row gap-3">
                        <button
                            onClick={() => navigate('/dashboard')}
                            className="inline-flex items-center justify-center text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white transition duration-300 py-2 px-4 rounded text-sm bg-emerald-500 bg-opacity-10 w-full sm:w-auto"
                        >
                            <FaCheck className="mr-2" />
                            Done
                        </button>
                        {/* Optionally add a "View History" button if you want parity with other flows */}

                        <button
                            onClick={() => navigate('/transactions')}
                            className="inline-flex items-center justify-center text-cyan-500 border border-cyan-500 hover:bg-cyan-500 hover:text-white transition duration-300 py-2 px-4 rounded text-sm bg-cyan-500 bg-opacity-10 w-full sm:w-auto"
                        >
                            <FaHistory className="mr-2" />
                            View History
                        </button>

                    </div>
                </div>
            ) : (
                <>
                    {/* Error Message */}
                    {error && (
                        <div className="mb-4 p-4 text-sm rounded border-l-4 border-red-500 bg-red-50 text-red-600">
                            {error}
                        </div>
                    )}
                    {/* Icon */}
                    <div className="flex justify-center mb-6">
                        <img
                            src="https://d30sleee6j32ev.cloudfront.net/Withdrawals/images/withdrawal_placeholders/VyMztna5WprRJXWo.png"
                            alt="Withdrawal Icon"
                            className="w-20 h-auto"
                        />
                    </div>
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                        {/* Currency Dropdown */}
                        <div>
                            <label
                                htmlFor="currency"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Currency
                            </label>
                            <select
                                name="currency"
                                value={withdrawDetails.currency}
                                onChange={handleChange}
                                className="w-full text-sm p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                required
                            >
                                <option value="">Select Currency</option>
                                {currencies.map((account, index) => (
                                    <option key={index} value={account.currency}>
                                        {account.currency}
                                    </option>
                                ))}
                            </select>
                            {withdrawDetails.currency && (
                                <div className="mt-1 text-xs text-green-700">
                                    {withdrawDetails.currency} balance: {selectedCurrencyBalance}
                                </div>
                            )}
                        </div>

                        {/* PayPal Email */}
                        <div>
                            <label
                                htmlFor="email"
                                className="block mb-1 text-sm font-medium text-gray-700"
                            >
                                PayPal Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={withdrawDetails.email}
                                onChange={handleChange}
                                className="w-full text-sm p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                placeholder="name@example.com"
                                required
                            />
                        </div>

                        {/* Amount */}
                        <div>
                            <label
                                htmlFor="amount"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Amount
                            </label>
                            <input
                                type="number"
                                name="amount"
                                value={withdrawDetails.amount}
                                onChange={handleChange}
                                className={`w-full text-sm p-2 border rounded focus:outline-none ${inputError
                                        ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                                        : 'focus:ring-emerald-500 focus:border-emerald-500'
                                    }`}
                                placeholder="Enter amount"
                                required
                            />
                            {inputError && (
                                <p className="text-xs text-red-500 mt-1">{inputError}</p>
                            )}
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            disabled={
                                isSubmitting ||
                                inputError ||
                                !withdrawDetails.amount ||
                                !withdrawDetails.email ||
                                !withdrawDetails.currency
                            }
                            className={`flex justify-center items-center w-full text-white py-2 px-4 rounded transition duration-300 ${isSubmitting ||
                                    inputError ||
                                    !withdrawDetails.amount ||
                                    !withdrawDetails.email ||
                                    !withdrawDetails.currency
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-emerald-500 hover:bg-emerald-600'
                                }`}
                        >
                            {isSubmitting ? (
                                <>
                                    <FaSpinner className="animate-spin mr-2" />
                                    Initiating withdrawal...
                                </>
                            ) : (
                                <>
                                    <HiOutlineDownload className="mr-2" />
                                    Withdraw Funds
                                </>
                            )}
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default PaypalWithdrawal;

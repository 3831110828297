import React, { useState, useEffect } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaCrown,
  FaSpinner,
} from 'react-icons/fa';
import Confetti from 'react-confetti';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FreeAnimation from './lottie/Free-lottie.json';
import premiumAnimation from './lottie/premium-lottie.json';
import BusinessAnimation from './lottie/business-lottie.json';

import api from '../api';
import { useUser } from './context';

const Membership = () => {
  const { user, login } = useUser();

  const [isUpgradingPremium, setIsUpgradingPremium] = useState(false);
  const [isUpgradingBusiness, setIsUpgradingBusiness] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [visibleBenefit, setVisibleBenefit] = useState(null);
  const [isBusiness, setIsBusiness] = useState(false);

  const navigate = useNavigate();

  // Fetch user data on mount
  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
        };
        const response = await api.get('/api/auth/info', config);
        if (response.status === 200) {
          const updatedUser = { ...user, ...response.data };
          login(updatedUser);

          // Check if user is a business + premium
          if (updatedUser.accountType === 'business' && updatedUser.isPremium) {
            setIsBusiness(true);
          } else {
            setIsBusiness(false);
          }
        } else {
          console.error('Failed to fetch user data, status code:', response.status);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [user, navigate, login]);

  const premiumBenefits = [
    {
      title: 'Unlimited Donation Links',
      description:
        'Create as many donation links as you need to support various causes and initiatives.',
    },
    {
      title: 'Instant Withdrawals',
      description:
        'Get immediate access to your funds with our expedited withdrawal process.',
    },
    {
      title: 'Unlimited Volunteer Programs',
      description:
        'Join or organize an unlimited number of volunteer programs to expand your social impact.',
    },
    {
      title: 'Advanced Analytics',
      description:
        'Access detailed reports and analytics to track and optimize your fundraising efforts.',
    },
    {
      title: 'Priority Support',
      description:
        'Receive priority customer service with faster response times and dedicated support.',
    },
    {
      title: 'Networking Opportunities',
      description:
        'Connect with a network of like-minded individuals and organizations for collaboration.',
    },
    {
      title: 'Customizable Fundraising Pages',
      description:
        'Personalize your fundraising pages with advanced customization options for greater impact.',
    },
    {
      title: 'Promotional Features',
      description:
        'Get your campaigns and programs featured in our promotional channels for increased visibility.',
    },
    {
      title: 'Educational Resources',
      description:
        'Access exclusive webinars, tutorials, and guides to enhance your fundraising skills.',
    },
  ];

  const businessBenefits = [
    {
      title: 'Corporate Sponsorship Opportunities',
      description:
        'Gain access to exclusive corporate sponsorship opportunities to support your initiatives.',
    },
    {
      title: 'Dedicated Account Manager',
      description: 'Receive personalized support from a dedicated account manager.',
    },
    {
      title: 'Advanced Fundraising Tools',
      description:
        'Utilize advanced tools to streamline your fundraising efforts and maximize donations.',
    },
    {
      title: 'Priority Feature Requests',
      description: 'Get priority consideration for feature requests and new functionality.',
    },
    {
      title: 'Exclusive Networking Events',
      description:
        'Attend exclusive events designed for business tier members to network and collaborate.',
    },
    {
      title: 'Enhanced Visibility',
      description:
        'Benefit from enhanced visibility for your campaigns through our promotional channels.',
    },
    {
      title: 'Custom Reports',
      description: 'Receive custom reports tailored to your specific fundraising needs.',
    },
    {
      title: 'Exclusive Grant Applications',
      description:
        'Gain eligibility to apply for special grants exclusively available to business members.',
    },
    {
      title: 'Event Invitations',
      description:
        'Receive invitations to exclusive events and conferences for networking and learning.',
    },
    {
      title: 'Recognition and Awards',
      description:
        'Become eligible for member recognition awards to showcase your achievements.',
    },
  ];

  // Toggle expanded benefit
  const toggleBenefit = (benefit) => {
    setVisibleBenefit(visibleBenefit === benefit ? null : benefit);
  };

  // Handle membership upgrade
  const handleUpgrade = async (tier) => {
    if (tier === 'premium') {
      setIsUpgradingPremium(true);
    } else if (tier === 'business') {
      setIsUpgradingBusiness(true);
    }

    try {
      const endpoint =
        tier === 'premium'
          ? '/api/auth/upgrade-membership'
          : '/api/auth/upgrade-business';

      const response = await api.patch(endpoint, {}, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        const updatedUser = {
          ...user,
          isPremium: tier === 'premium',
          accountType: tier === 'business' ? 'business' : user.accountType,
        };
        login(updatedUser);

        // Check if user is business + premium
        if (updatedUser.accountType === 'business' && updatedUser.isPremium) {
          setIsBusiness(true);
        } else {
          setIsBusiness(false);
        }

        toast.success(`Membership upgraded to ${tier}!`);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
      } else {
        toast.error('Failed to upgrade membership.');
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || 'An error occurred during the upgrade.'
      );
    } finally {
      if (tier === 'premium') {
        setIsUpgradingPremium(false);
      } else if (tier === 'business') {
        setIsUpgradingBusiness(false);
      }
    }
  };

  return (
    <div
      className="
        min-h-screen 
        bg-gradient-to-r from-white to-emerald-50
        py-8 
      "
    >
      <ToastContainer position="top-center" />
      {showConfetti && <Confetti />}

      <div className="max-w-6xl mx-auto px-4">
        {/* Page Title */}
        <h2 className="text-3xl font-extrabold mb-6 text-center text-emerald-600">
          Membership Plans
        </h2>
        <p className="text-center text-sm text-gray-600 mb-10">
          Choose the plan that best fits your fundraising and volunteering needs.
        </p>

        <div
          className="
            grid 
            grid-cols-1 
            md:grid-cols-3 
            gap-8 
            items-start 
            lg:gap-10
          "
        >
          {/* Free Tier Card */}
          <div
            className="
              rounded-lg 
              flex flex-col 
              bg-white 
              shadow-xl 
              p-6
              border 
              border-gray-100
              hover:shadow-2xl 
              transition-shadow 
              duration-300
            "
          >
            <h3 className="text-xl font-extrabold text-center mb-4 text-gray-800 uppercase tracking-wide">
              Free Tier
            </h3>
            <div className="mx-auto mb-4">
              <Lottie
                animationData={FreeAnimation}
                style={{ width: 140, height: 140 }}
              />
            </div>
            <p className="text-center text-2xl font-bold text-blue-600 mb-2">
              $0 <span className="text-sm text-gray-400">/ year</span>
            </p>
            <p className="text-xs text-center text-gray-500 mb-6">
              Basic features to start your journey
            </p>
            <ul className="text-sm text-gray-600 space-y-1 mb-6 flex-1">
              <li className="flex items-center">
                • Participate in <strong className="ml-1">1 volunteer program</strong>
              </li>
              <li className="flex items-center">
                • Create <strong className="ml-1">1 donation link</strong>
              </li>
              <li className="flex items-center">
                • <strong className="ml-1">No access</strong> to grants
              </li>
            </ul>
            <button
              className="
                bg-gray-300 
                text-gray-700 
                py-2 
                rounded-lg 
                hover:bg-gray-400 
                transition 
                duration-300
                w-full
              "
            >
              Stay on Free
            </button>
          </div>

          {/* Premium Tier Card */}
          <div
            className="
              rounded-lg 
              flex flex-col 
              bg-white 
              shadow-xl 
              p-6
              border 
              border-gray-100
              hover:shadow-2xl 
              transition-shadow 
              duration-300
              relative
            "
          >
            {/* "Most Popular" badge, optional */}
            <div
              className="
                absolute 
                -top-3 
                right-3 
                bg-yellow-400 
                text-white 
                text-xs 
                font-bold 
                py-1 
                px-2 
                rounded-full 
                shadow
              "
            >
              Most Popular
            </div>
            <h3 className="text-xl font-extrabold text-center mb-4 text-gray-800 uppercase tracking-wide">
              Premium Tier
            </h3>
            <div className="mx-auto mb-4">
              <Lottie
                animationData={premiumAnimation}
                style={{ width: 140, height: 140 }}
              />
            </div>
            <p className="text-center text-2xl font-bold text-yellow-600 mb-2">
              50 Points<span className="text-base ml-1 text-gray-500">/year</span>
            </p>
            <p className="text-xs text-center text-gray-500 mb-6">
              Unlock advanced features and analytics
            </p>

            <ul className="text-sm text-gray-700 mb-6 space-y-3 flex-1">
              {premiumBenefits.map((benefit, index) => (
                <li key={index} className="cursor-pointer group relative">
                  <div
                    onClick={() => toggleBenefit(benefit.title)}
                    className="
                      flex 
                      items-center 
                      justify-between
                      hover:text-emerald-600
                      transition-colors
                    "
                  >
                    <strong>{benefit.title}</strong>
                    {visibleBenefit === benefit.title ? (
                      <FaChevronUp className="ml-2 text-xs" />
                    ) : (
                      <FaChevronDown className="ml-2 text-xs" />
                    )}
                  </div>
                  {visibleBenefit === benefit.title && (
                    <p className="mt-1 text-gray-600 text-xs">
                      {benefit.description}
                    </p>
                  )}
                </li>
              ))}
            </ul>

            <button
              onClick={() => handleUpgrade('premium')}
              disabled={isUpgradingPremium || user.isPremium}
              className={`
                w-full 
                py-2 
                rounded-lg 
                transition 
                duration-300 
                text-white 
                ${
                  isUpgradingPremium
                    ? 'bg-yellow-300'
                    : user.isPremium
                    ? 'bg-gray-400'
                    : 'bg-yellow-500 hover:bg-yellow-600'
                }
              `}
            >
              {isUpgradingPremium ? (
                <>
                  <FaSpinner className="animate-spin mr-2 inline" />
                  Upgrading...
                </>
              ) : user.isPremium ? (
                <>
                  <FaCrown className="mr-2 inline" />
                  Upgraded
                </>
              ) : (
                'Upgrade to Premium'
              )}
            </button>
          </div>

          {/* Business Tier Card */}
          <div
            className="
              rounded-lg 
              flex flex-col 
              bg-white 
              shadow-xl 
              p-6
              border 
              border-gray-100
              hover:shadow-2xl 
              transition-shadow 
              duration-300
            "
          >
            <h3 className="text-xl font-extrabold text-center mb-4 text-gray-800 uppercase tracking-wide">
              Business Tier
            </h3>
            <div className="mx-auto mb-4">
              <Lottie
                animationData={BusinessAnimation}
                style={{ width: 140, height: 140 }}
              />
            </div>
            <p className="text-center text-2xl font-bold text-green-600 mb-2">
              200 Points<span className="text-base ml-1 text-gray-500">/year</span>
            </p>
            <p className="text-xs text-center text-gray-500 mb-6">
              Maximize your corporate social impact
            </p>

            <ul className="text-sm text-gray-700 mb-6 space-y-3 flex-1">
              {businessBenefits.map((benefit, index) => (
                <li key={index} className="cursor-pointer group relative">
                  <div
                    onClick={() => toggleBenefit(benefit.title)}
                    className="
                      flex 
                      items-center 
                      justify-between
                      hover:text-emerald-600
                      transition-colors
                    "
                  >
                    <strong>{benefit.title}</strong>
                    {visibleBenefit === benefit.title ? (
                      <FaChevronUp className="ml-2 text-xs" />
                    ) : (
                      <FaChevronDown className="ml-2 text-xs" />
                    )}
                  </div>
                  {visibleBenefit === benefit.title && (
                    <p className="mt-1 text-gray-600 text-xs">
                      {benefit.description}
                    </p>
                  )}
                </li>
              ))}
            </ul>

            <button
              onClick={() => handleUpgrade('business')}
              disabled={isUpgradingBusiness || isBusiness}
              className={`
                w-full 
                py-2 
                rounded-lg 
                transition 
                duration-300 
                text-white 
                ${
                  isUpgradingBusiness
                    ? 'bg-green-300'
                    : isBusiness
                    ? 'bg-gray-400'
                    : 'bg-green-500 hover:bg-green-600'
                }
              `}
            >
              {isUpgradingBusiness ? (
                <>
                  <FaSpinner className="animate-spin mr-2 inline" />
                  Upgrading...
                </>
              ) : isBusiness ? (
                <>
                  <FaCrown className="mr-2 inline" />
                  Upgraded
                </>
              ) : (
                'Upgrade to Business'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;

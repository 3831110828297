import React, { useState, useEffect } from 'react';
import api from '../../api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HelpFaqEdit = ({ faqId, onClose }) => {
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    category: '',
    subCategory: '',
    tags: [],
    published: false,
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Fetch categories
    const fetchCategories = async () => {
      try {
        const { data } = await api.get('/api/help/categories');
        setCategories(data.categories || []);
      } catch (err) {
        console.error('Error fetching categories:', err);
        toast.error('Failed to load categories. Please try again.');
      }
    };

    // Fetch FAQ details
    const fetchFaq = async () => {
      try {
        const { data } = await api.get(`/api/help/faqs/${faqId}`);
        setFormData({
          question: data.faq.question,
          answer: data.faq.answer,
          category: data.faq.category || '',
          subCategory: data.faq.subCategory || '',
          tags: data.faq.tags || [],
          published: data.faq.published || false,
        });
      } catch (err) {
        console.error('Error fetching FAQ:', err);
        toast.error('Failed to load FAQ details.');
      }
    };

    fetchCategories();
    fetchFaq();
  }, [faqId]);

  useEffect(() => {
    // Fetch subcategories based on the selected category
    const fetchSubCategories = async () => {
      if (!formData.category) {
        setSubCategories([]);
        return;
      }
      try {
        const { data } = await api.get(`/api/help/subcategories?category=${formData.category}`);
        setSubCategories(data.subCategories || []);
      } catch (err) {
        console.error('Error fetching subcategories:', err);
        toast.error('Failed to load subcategories. Please try again.');
      }
    };
    fetchSubCategories();
  }, [formData.category]);

  useEffect(() => {
    // Fetch articles based on the selected subcategory
    const fetchArticles = async () => {
      if (!formData.subCategory) {
        setArticles([]);
        return;
      }
      try {
        const { data } = await api.get(`/api/help/articles-by-subcategory?subCategory=${formData.subCategory}`);
        setArticles(data.articles || []);
      } catch (err) {
        console.error('Error fetching articles:', err);
        toast.error('Failed to load articles for the selected subcategory.');
      }
    };
    fetchArticles();
  }, [formData.subCategory]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'tags' ? value.split(',').map((tag) => tag.trim()) : value,
    }));
  };

  const handleAnswerChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      answer: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await api.put(`/api/help/faqs/${faqId}`, formData);
      if (response.status === 200) {
        toast.success('FAQ updated successfully!');
        onClose();
      }
    } catch (err) {
      console.error('Error updating FAQ:', err);
      toast.error('Failed to update FAQ. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded shadow">
      <ToastContainer />
      <h2 className="text-lg font-bold text-gray-800 mb-4">Edit FAQ</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Question</label>
          <input
            type="text"
            name="question"
            value={formData.question}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2 mt-1"
            placeholder="Enter question"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Answer</label>
          <ReactQuill
            theme="snow"
            value={formData.answer}
            onChange={handleAnswerChange}
            className="bg-white"
          />
        </div>
        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Category</label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2 mt-1"
            required
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        {formData.category && (
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">Subcategory</label>
            <select
              name="subCategory"
              value={formData.subCategory}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2 mt-1"
            >
              <option value="">Select a subcategory</option>
              {subCategories.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {formData.subCategory && (
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">Related Articles</label>
            <select
              name="relatedArticle"
              value={formData.relatedArticle}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2 mt-1"
            >
              <option value="">Select an article</option>
              {articles.map((article) => (
                <option key={article._id} value={article._id}>
                  {article.title}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Tags</label>
          <input
            type="text"
            name="tags"
            value={formData.tags.join(', ')}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2 mt-1"
            placeholder="Comma-separated tags"
          />
        </div>
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            name="published"
            checked={formData.published}
            onChange={(e) => setFormData((prev) => ({ ...prev, published: e.target.checked }))}
            className="mr-2"
          />
          <label className="font-semibold text-gray-700">Published</label>
        </div>
        <div className="flex justify-between items-center">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition"
          >
            {isSubmitting ? 'Updating...' : 'Update FAQ'}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="text-red-600 hover:underline"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default HelpFaqEdit;

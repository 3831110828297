/**
 * TradeDiscussion.js
 * The "Discussion" tab content with:
 *  - Moderator note
 *  - Upvotes / downvotes
 *  - Profile pics
 *  - "Comment" & "Reply" forms for nested threads
 *  - Tagging (highlighting @mentions)
 *  - Dummy data for existing comments
 *  - Reply forms hidden until user clicks 'Reply'
 */

import React, { useState } from 'react';
import {
  FaInfoCircle,
  FaUserShield,
  FaThumbsUp,
  FaThumbsDown,
  FaUserCircle,
  FaPaperPlane,
  FaReply
} from 'react-icons/fa';

// Helper function to highlight @mentions in text
const highlightMentions = (text) => {
  // Simple regex for @mentions: matches words of letters, digits, or underscores
  const mentionRegex = /(@[\w\d]+)/g;
  // Split text into parts based on mentions
  const parts = text.split(mentionRegex);
  return parts.map((part, i) => {
    if (part.match(mentionRegex)) {
      // Return a span to highlight mention
      return (
        <span key={i} className="bg-yellow-100 text-yellow-800 px-1 rounded-sm">
          {part}
        </span>
      );
    }
    return <span key={i}>{part}</span>;
  });
};

const TradeDiscussion = () => {
  // Dummy data, each comment can have nested "replies"
  const [comments, setComments] = useState([
    {
      id: 1,
      author: 'Nathan Carter',
      profilePic: '',
      text: "Does anyone know if Lucy handles trades over 300 points? I'm curious if there's a cap.",
      upvotes: 5,
      downvotes: 1,
      postedAt: '3 hours ago',
      replies: [],
    },
    {
      id: 2,
      author: 'Anonymous',
      profilePic: '',
      text: "I think she does, but you'd need to confirm directly. @NathanCarter Have you tried reaching out?",
      upvotes: 8,
      downvotes: 0,
      postedAt: '2 hours ago',
      replies: [],
    },
    {
      id: 3,
      author: 'Ashley Gomez',
      profilePic: '',
      text: "@Anonymous Yes, she handles large trades! I completed a 500-point trade with her last week—super smooth!",
      upvotes: 12,
      downvotes: 0,
      postedAt: '1 hour ago',
      replies: [
        {
          id: 101,
          author: 'Samuel Price',
          profilePic: '',
          text: "@AshleyGomez Thanks for sharing! Do you remember how long the transaction took? I'm considering something similar.",
          upvotes: 6,
          downvotes: 1,
          postedAt: '45 minutes ago',
          replies: [
            {
              id: 102,
              author: 'Ashley Gomez',
              profilePic: '',
              text: "@SamuelPrice It took about 20 minutes for everything to go through. She was very responsive!",
              upvotes: 10,
              downvotes: 0,
              postedAt: '30 minutes ago',
              replies: [],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      author: 'Michael Rivera',
      profilePic: '',
      text: "@NathanCarter I’ve heard there’s no cap, but larger trades might take a bit longer to process. Just my two cents!",
      upvotes: 4,
      downvotes: 2,
      postedAt: '20 minutes ago',
      replies: [],
    },
    {
      id: 7,
      author: 'Anonymous',
      profilePic: '',
      text: "Anyone have issues with delayed responses from Lucy? I’ve heard mixed reviews.",
      upvotes: 3,
      downvotes: 5,
      postedAt: '2 hours ago',
      replies: [
        {
          id: 201,
          author: 'Elena Ward',
          profilePic: '',
          text: "@Anonymous I’ve traded with her multiple times and never had delays. Maybe it depends on the time of day?",
          upvotes: 9,
          downvotes: 1,
          postedAt: '1 hour ago',
          replies: [
            {
              id: 202,
              author: 'Trevor Blake',
              profilePic: '',
              text: "Can anyone confirm if refunds are processed immediately if something goes wrong? @ElenaWard @AshleyGomez",
              upvotes: 7,
              downvotes: 0,
              postedAt: '30 minutes ago',
              replies: [
                {
                  id: 203,
                  author: 'Elena Ward',
                  profilePic: '',
                  text: "@TrevorBlake I had one refund situation—it was processed within an hour. Lucy is reliable in that regard.",
                  upvotes: 11,
                  downvotes: 0,
                  postedAt: '15 minutes ago',
                  replies: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  // For new top-level comments
  const [newComment, setNewComment] = useState('');

  // For controlling reply text
  const [replyTexts, setReplyTexts] = useState({});

  // **New**: For toggling if reply box is open for a certain comment ID
  const [replyOpen, setReplyOpen] = useState({});

  // Increase or decrease votes on a comment or reply
  const handleVote = (commentId, type) => {
    setComments((prevComments) => {
      // Helper to recursively update votes
      const updateVotes = (items) =>
        items.map((item) => {
          if (item.id === commentId) {
            if (type === 'up') {
              return { ...item, upvotes: item.upvotes + 1 };
            } else {
              return { ...item, downvotes: item.downvotes + 1 };
            }
          }
          if (item.replies && item.replies.length > 0) {
            return { ...item, replies: updateVotes(item.replies) };
          }
          return item;
        });

      return updateVotes(prevComments);
    });
  };

  // Submit a new top-level comment
  const handleAddComment = (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    const newId = Date.now();
    const newCommentObj = {
      id: newId,
      author: 'You',
      profilePic: '',
      text: newComment.trim(),
      upvotes: 0,
      downvotes: 0,
      postedAt: 'just now',
      replies: [],
    };
    setComments([newCommentObj, ...comments]);
    setNewComment('');
  };

  // Toggle the reply input for a given comment
  const toggleReplyBox = (commentId) => {
    setReplyOpen((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // Toggle
    }));
  };

  // Handle reply text change
  const handleReplyChange = (commentId, text) => {
    setReplyTexts((prev) => ({
      ...prev,
      [commentId]: text,
    }));
  };

  // Submit reply
  const handleAddReply = (e, commentId) => {
    e.preventDefault();
    const replyText = replyTexts[commentId]?.trim();
    if (!replyText) return;

    setComments((prevComments) => {
      const addReplyToComment = (items) =>
        items.map((item) => {
          if (item.id === commentId) {
            const newReply = {
              id: Date.now(),
              author: 'You',
              profilePic: '',
              text: replyText,
              upvotes: 0,
              downvotes: 0,
              postedAt: 'just now',
              replies: [],
            };
            return { ...item, replies: [...item.replies, newReply] };
          }
          if (item.replies && item.replies.length > 0) {
            return { ...item, replies: addReplyToComment(item.replies) };
          }
          return item;
        });
      return addReplyToComment(prevComments);
    });

    // Reset the reply text and hide the box
    setReplyTexts((prev) => ({ ...prev, [commentId]: '' }));
    setReplyOpen((prev) => ({ ...prev, [commentId]: false }));
  };

  // Component to display a single comment + nested replies
  const CommentItem = ({ comment }) => {
    const isReplyBoxOpen = !!replyOpen[comment.id];
    const replyValue = replyTexts[comment.id] || '';

    return (
      <div className="mt-6 space-y-6">
        <div className="border border-gray-200 rounded p-3 text-sm text-gray-700 shadow-sm">
          {/* Comment header */}
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {comment.profilePic ? (
                <img
                  src={comment.profilePic}
                  alt="avatar"
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <FaUserCircle className="text-gray-400" size="1.5em" />
              )}
              <span className="font-semibold">{comment.author}</span>
              <span className="text-xs text-gray-400">{comment.postedAt}</span>
            </div>
          </div>

          {/* Comment text */}
          <p className="mt-2 text-gray-600 break-words">
            {highlightMentions(comment.text)}
          </p>

          {/* Vote + Reply row */}
          <div className="mt-3 flex items-center space-x-4 text-xs">
            <button
              onClick={() => handleVote(comment.id, 'up')}
              className="flex items-center space-x-1 text-green-600 hover:text-green-700"
            >
              <FaThumbsUp />
              <span>{comment.upvotes}</span>
            </button>
            <button
              onClick={() => handleVote(comment.id, 'down')}
              className="flex items-center space-x-1 text-red-600 hover:text-red-700"
            >
              <FaThumbsDown />
              <span>{comment.downvotes}</span>
            </button>
            {/* Clicking "Reply" toggles the reply input */}
            <button
              className="flex items-center space-x-1 text-emerald-500 hover:text-emerald-600"
              onClick={() => toggleReplyBox(comment.id)}
            >
              <FaReply />
              <span>Reply</span>
            </button>
          </div>

          {/* Nested replies */}
          {comment.replies && comment.replies.length > 0 && (
            <div className="ml-6 mt-4 border-l border-gray-200 pl-4">
              {comment.replies.map((reply) => (
                <CommentItem key={reply.id} comment={reply} />
              ))}
            </div>
          )}

          {/* Conditionally render reply form if isReplyBoxOpen */}
          {isReplyBoxOpen && (
            <form
              className="mt-4 flex flex-col"
              onSubmit={(e) => handleAddReply(e, comment.id)}
            >
              <label
                htmlFor={`replyField-${comment.id}`}
                className="mb-1 text-xs font-semibold text-gray-700"
              >
                Reply to {comment.author}
              </label>
              <textarea
                id={`replyField-${comment.id}`}
                rows="2"
                className="p-2 border border-gray-300 rounded focus:ring-emerald-500 focus:border-emerald-500 text-sm"
                placeholder="Write a reply..."
                value={replyValue}
                onChange={(e) => handleReplyChange(comment.id, e.target.value)}
              />
              <div className="flex justify-end space-x-2 mt-2">
                <button
                  type="button"
                  onClick={() =>
                    setReplyOpen((prev) => ({ ...prev, [comment.id]: false }))
                  }
                  className="text-xs text-gray-500 hover:text-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center bg-emerald-500 text-white px-3 py-1 text-xs sm:text-sm rounded hover:bg-emerald-600 transition"
                >
                  <FaPaperPlane className="mr-1" />
                  Post Reply
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl w-full bg-white p-4 border border-gray-300 my-4 rounded-lg shadow-md">
      <h2 className="text-emerald-600 font-extrabold text-lg mb-3">Discussion</h2>

      {/* Alert: respectful behavior */}
      <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 text-sm rounded">
        <p className="flex items-center">
          <FaUserShield className="mr-1 text-red-500" />
          <span>
            Moderator Note: Please keep discussions respectful and on-topic.
            Violations may result in account restrictions.
          </span>
        </p>
      </div>

      {/* Intro text */}
      <p className="text-sm text-gray-600 mb-2">
        Welcome to the discussion board! Ask questions about the offer, trade
        process, or share your experience with Lucy.
      </p>

      {/* Another alert or tips */}
      <div className="my-4 p-3 bg-emerald-50 border border-emerald-200 rounded">
        <p className="text-xs text-gray-800 flex items-center">
          <FaInfoCircle className="mr-1 text-emerald-500" />
          Feel free to post your queries here. Lucy or other users will respond
          shortly. Use <span className="mx-1 font-bold">@username</span> to tag
          someone specific.
        </p>
      </div>

      {/* Moderator comment example */}
      <div className="border border-yellow-200 bg-yellow-50 rounded p-3 mt-4 text-sm text-gray-700 shadow-sm">
        <p className="font-semibold flex items-center space-x-2">
          <FaUserShield className="text-yellow-500" />
          <span>Moderator</span>
        </p>
        <p className="mt-1">
          Hello everyone! Remember to abide by our community guidelines. Keep it
          polite and constructive. Thank you!
        </p>
      </div>

      {/* Render existing comments */}
      <div className="mt-6">
        {comments.map((comment) => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
      </div>

      {/* Add a new top-level comment form */}
      <form onSubmit={handleAddComment} className="mt-8 flex flex-col">
        <label
          htmlFor="newComment"
          className="mb-1 text-sm font-semibold text-gray-700"
        >
          Add your comment
        </label>
        <textarea
          id="newComment"
          rows="3"
          className="p-2 border border-gray-300 rounded focus:ring-emerald-500 focus:border-emerald-500 text-sm"
          placeholder="Write something about your experience or question..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />

        <button
          type="submit"
          className="mt-2 self-end inline-flex items-center bg-emerald-500 text-white px-4 py-2 text-xs sm:text-sm rounded hover:bg-emerald-600 transition"
        >
          <FaPaperPlane className="mr-1" />
          Post Comment
        </button>
      </form>
    </div>
  );
};

export default TradeDiscussion;

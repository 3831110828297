import React, { useState, useEffect } from 'react';
import api from '../../api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useUser } from '../context';

const HelpFaqForm = ({ onClose }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    category: '',
    subCategory: '',
    relatedArticle: '',
    tags: [],
    published: false,
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [skipSubCategory, setSkipSubCategory] = useState(false);

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Header dropdown
      ['bold', 'italic', 'underline', 'strike'], // Text formatting
      [{ list: 'ordered' }, { list: 'bullet' }], // Lists
      [{ script: 'sub' }, { script: 'super' }], // Subscript/Superscript
      ['blockquote', 'code-block'], // Blockquote/code block
      [{ indent: '-1' }, { indent: '+1' }], // Indentation
      [{ align: [] }], // Text alignment
      ['link', 'image', 'video'], // Link, image, and video
      ['clean'], // Clear formatting
    ],
  };

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'script',
    'align',
    'blockquote',
    'code-block',
    'link',
    'image',
    'video',
  ];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await api.get('/api/help/categories');
        setCategories(data.categories || []);
      } catch (err) {
        console.error('Error fetching categories:', err);
        toast.error('Failed to fetch categories. Please try again.');
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (!formData.category) {
        setSubCategories([]);
        return;
      }
      try {
        const { data } = await api.get(`/api/help/subcategories?category=${formData.category}`);
        setSubCategories(data.subCategories || []);
      } catch (err) {
        console.error('Error fetching subcategories:', err);
        toast.error('Failed to fetch subcategories. Please try again.');
      }
    };
    if (!skipSubCategory) fetchSubCategories();
  }, [formData.category, skipSubCategory]);

  useEffect(() => {
    const fetchArticles = async () => {
      if (!formData.category) {
        setArticles([]);
        return;
      }
      try {
        const url = skipSubCategory
          ? `/api/help/articles?category?=${formData.category}`
          : `/api/help/articles-by-subcategory?subCategory=${formData.subCategory}`;
        const { data } = await api.get(url);
        setArticles(data.articles || []);
      } catch (err) {
        console.error('Error fetching articles:', err);
        toast.error('Failed to fetch articles. Please try again.');
      }
    };
    if (skipSubCategory || formData.subCategory) fetchArticles();
  }, [formData.category, formData.subCategory, skipSubCategory]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'tags' ? value.split(',').map((tag) => tag.trim()) : value,
    }));
  };

  const handleAnswerChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      answer: value,
    }));
  };

  const handleModalConfirm = () => {
    setShowModal(false);
    submitFaq();
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.published) {
      setShowModal(true);
    } else {
      submitFaq();
    }
  };

  const submitFaq = async () => {
    setIsSubmitting(true);
    try {
      const response = await api.post('/api/help/faqs', formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (response.status === 201) {
        toast.success('FAQ created successfully!');
        setFormData({
          question: '',
          answer: '',
          category: '',
          subCategory: '',
          relatedArticle: '',
          tags: [],
          published: false,
        });
        onClose();
      }
    } catch (err) {
      console.error('Error creating FAQ:', err);
      toast.error('Failed to create FAQ. Please check your inputs.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded shadow">
      <ToastContainer />
      <h2 className="text-lg font-bold text-gray-800 mb-4">Create FAQ</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Question</label>
          <input
            type="text"
            name="question"
            value={formData.question}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2 mt-1"
            placeholder="Enter question"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Answer</label>
          <ReactQuill
            theme="snow"
            value={formData.answer}
            onChange={handleAnswerChange}
            modules={quillModules}
            formats={quillFormats}
            className="bg-white"
          />
        </div>

        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Category</label>
          <select
            name="category"
            value={formData.category}
            onChange={(e) => {
              handleChange(e);
              setSkipSubCategory(false);
              setFormData((prev) => ({ ...prev, subCategory: '' }));
            }}
            className="w-full border rounded px-3 py-2 mt-1"
            required
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        {formData.category && !skipSubCategory && (
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">Subcategory</label>
            <select
              name="subCategory"
              value={formData.subCategory}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2 mt-1"
            >
              <option value="">Select a subcategory</option>
              {subCategories.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </select>
            <button
              type="button"
              onClick={() => {
                setSkipSubCategory(true);
                setFormData((prev) => ({ ...prev, subCategory: '' }));
              }}
              className="text-blue-600 underline mt-2"
            >
              No Subcategory
            </button>
          </div>
        )}

        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Parent Article</label>
          <select
            name="relatedArticle"
            value={formData.relatedArticle}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2 mt-1"
          >
            <option value="">Select a parent article</option>
            {articles.map((article) => (
              <option key={article._id} value={article._id}>
                {article.title}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block font-semibold text-gray-700">Tags</label>
          <input
            type="text"
            name="tags"
            value={formData.tags.join(', ')}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2 mt-1"
            placeholder="Comma-separated tags"
          />
        </div>

        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            name="published"
            checked={formData.published}
            onChange={(e) => setFormData((prev) => ({ ...prev, published: e.target.checked }))}
            className="mr-2"
          />
          <label className="font-semibold text-gray-700">Published</label>
        </div>

        <div className="flex justify-between items-center">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition"
          >
            {isSubmitting ? 'Creating...' : 'Create FAQ'}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="text-red-600 hover:underline"
          >
            Cancel
          </button>
        </div>
      </form>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg max-w-sm">
            <h3 className="text-lg font-semibold mb-4 text-gray-800">
              You have not marked this FAQ as published.
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Are you sure you want to proceed without publishing?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleModalCancel}
                className="px-4 py-2 text-sm font-semibold text-gray-600 border border-gray-300 rounded hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={handleModalConfirm}
                className="px-4 py-2 text-sm font-semibold text-white bg-green-600 rounded hover:bg-green-700"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpFaqForm;

import React, { useState, useEffect } from 'react';
import { useUser } from '../context';
import api from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SubCategoryModal from './SubCategoryModal';

function HelpArticleForm() {
    const { user } = useUser();
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [tags, setTags] = useState('');
    const [countryCodes, setCountryCodes] = useState('');
    const [published, setPublished] = useState(false);
    const [content, setContent] = useState('');
    const [thumbnail, setThumbnail] = useState(null);
    const [publisherFirstName, setPublisherFirstName] = useState('');
    const [publisherLastName, setPublisherLastName] = useState('');
    const [publisherEmail, setPublisherEmail] = useState('');
    const [publisherPhoto, setPublisherPhoto] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [showSubCategoryModal, setShowSubCategoryModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const { data } = await api.get('/api/help/categories');
                setCategories(data.categories || []);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
                toast.error('Failed to fetch categories');
            }
        };
        fetchCategories();
    }, []);

    // Fetch subcategories based on the selected category
    useEffect(() => {
        const fetchSubCategories = async () => {
            if (!category) {
                setSubCategories([]);
                return;
            }
            try {
                const { data } = await api.get(`/api/help/subcategories?category=${category}`);
                setSubCategories(data.subCategories || []);
            } catch (error) {
                console.error('Failed to fetch subcategories:', error);
                toast.error('Failed to fetch subcategories');
            }
        };
        fetchSubCategories();
    }, [category]);

    // Handle subcategory creation
    const handleSubCategoryCreated = (newSubCategory) => {
        setSubCategories((prev) => [...prev, newSubCategory]);
        setSubCategory(newSubCategory._id);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate category before submitting
        if (!category) {
            toast.error('Please select a category before submitting.');
            return;
        }

        setIsSubmitting(true);

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('summary', summary);
            formData.append('content', content);
            formData.append('category', category);
            formData.append('subCategory', subCategory || ''); // Always submit subCategory (empty if not selected)
            formData.append('tags', tags);
            formData.append('published', published ? 'true' : 'false');

            // Parse country codes
            const codesArray = countryCodes.split(',').map((c) => c.trim()).filter((c) => c);
            codesArray.forEach((code) => formData.append('countryCodes[]', code));

            if (thumbnail) {
                formData.append('thumbnail', thumbnail);
            }

            formData.append('publisherFirstName', publisherFirstName);
            formData.append('publisherLastName', publisherLastName);
            formData.append('publisherEmail', publisherEmail);

            if (publisherPhoto) {
                formData.append('publisherPhoto', publisherPhoto);
            }

            await api.post('/api/help/articles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${user?.token || ''}`,
                },
            });

            toast.success('Article created successfully!');
            // Reset form
            setTitle('');
            setSummary('');
            setContent('');
            setCategory('');
            setSubCategory('');
            setTags('');
            setCountryCodes('');
            setPublished(false);
            setThumbnail(null);
            setPublisherFirstName('');
            setPublisherLastName('');
            setPublisherEmail('');
            setPublisherPhoto(null);
        } catch (error) {
            console.error('Error creating article:', error);
            toast.error('Failed to create article');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-md mt-10">
            <h2 className="text-2xl font-bold mb-6 text-gray-700">Create Help Article</h2>
            <form onSubmit={handleSubmit} className="space-y-5">
                {/* Title */}
                <div>
                    <label className="block text-gray-600 mb-2 font-medium">Title</label>
                    <input
                        type="text"
                        placeholder="Enter article title"
                        className="w-full p-3 border border-gray-300 rounded"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>

                {/* Summary */}
                <div>
                    <label className="block text-gray-600 mb-2 font-medium">Summary</label>
                    <textarea
                        placeholder="Short summary for the article..."
                        className="w-full p-3 border border-gray-300 rounded"
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                        required
                        maxLength={250}
                    />
                </div>

                {/* Category */}
                <div>
                    <label className="block text-gray-600 mb-2 font-medium">Category</label>
                    <select
                        className="w-full p-3 border border-gray-300 rounded"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    >
                        <option value="">-- Select Category --</option>
                        {categories.map((cat) => (
                            <option key={cat._id} value={cat._id}>
                                {cat.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Subcategory */}
                {category && (
                    <div>
                        <label className="block text-gray-600 mb-2 font-medium">Subcategory</label>
                        <select
                            className="w-full p-3 border border-gray-300 rounded"
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                        >
                            {!subCategory && <option value="">-- Select Subcategory --</option>}
                            {subCategories.map((sub) => (
                                <option key={sub._id} value={sub._id}>
                                    {sub.name}
                                </option>
                            ))}
                        </select>
                        <button
                            type="button"
                            className="text-blue-600 underline text-sm mt-2"
                            onClick={() => setShowSubCategoryModal(true)}
                        >
                            Need to create a subcategory?
                        </button>
                    </div>
                )}
                {showSubCategoryModal && (
                    <SubCategoryModal
                        category={category}
                        onClose={() => setShowSubCategoryModal(false)}
                        onSubCategoryCreated={handleSubCategoryCreated}
                    />
                )}

                {/* Tags */}
                <div>
                    <label className="block text-gray-600 mb-2 font-medium">
                        Tags (comma separated)
                    </label>
                    <input
                        type="text"
                        placeholder="e.g. security,account-management"
                        className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 transition"
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                    />
                </div>

                {/* Country Codes */}
                <div>
                    <label className="block text-gray-600 mb-2 font-medium">
                        Country Codes (comma separated)
                    </label>
                    <input
                        type="text"
                        placeholder="e.g. US, CA, UK"
                        className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 transition"
                        value={countryCodes}
                        onChange={(e) => setCountryCodes(e.target.value)}
                    />
                </div>

                {/* Publisher Info */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-gray-600 mb-2 font-medium">
                            Publisher First Name
                        </label>
                        <input
                            type="text"
                            placeholder="First name"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 transition"
                            value={publisherFirstName}
                            onChange={(e) => setPublisherFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-600 mb-2 font-medium">
                            Publisher Last Name
                        </label>
                        <input
                            type="text"
                            placeholder="Last name"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 transition"
                            value={publisherLastName}
                            onChange={(e) => setPublisherLastName(e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-gray-600 mb-2 font-medium">
                        Publisher Email
                    </label>
                    <input
                        type="email"
                        placeholder="Publisher email"
                        className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 transition"
                        value={publisherEmail}
                        onChange={(e) => setPublisherEmail(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label className="block text-gray-600 mb-2 font-medium">
                        Publisher Photo (optional)
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                       file:rounded file:border-0
                       file:text-sm file:font-semibold
                       file:bg-blue-50 file:text-blue-700
                       hover:file:bg-blue-100"
                        onChange={(e) => setPublisherPhoto(e.target.files[0])}
                    />
                </div>

                {/* Thumbnail Image */}
                <div>
                    <label className="block text-gray-600 mb-2 font-medium">
                        Thumbnail Image (optional)
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                       file:rounded file:border-0
                       file:text-sm file:font-semibold
                       file:bg-blue-50 file:text-blue-700
                       hover:file:bg-blue-100"
                        onChange={(e) => setThumbnail(e.target.files[0])}
                    />
                </div>

                {/* Publish Checkbox */}
                <div className="flex items-center space-x-3">
                    <label className="text-gray-600 font-medium">Publish Now?</label>
                    <input
                        type="checkbox"
                        checked={published}
                        onChange={() => setPublished(!published)}
                        className="h-5 w-5 text-blue-600 focus:ring-blue-500 rounded"
                    />
                </div>

                {/* Content - Rich Text */}
                <div>
                    <label className="block text-gray-600 mb-2 font-medium">Content</label>
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={{
                            toolbar: [
                                // Dropdowns for font family, size, and headers
                                [{ font: [] }, { size: [] }, { header: [1, 2, 3, 4, 5, 6, false] }],

                                // Inline formatting options
                                ['bold', 'italic', 'underline', 'strike'],

                                // Text color and background color
                                [{ color: [] }, { background: [] }],

                                // Script (subscript and superscript)
                                [{ script: 'sub' }, { script: 'super' }],

                                // Text alignment
                                [{ align: [] }],

                                // Lists and indentation
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                [{ indent: '-1' }, { indent: '+1' }],

                                // Direction (left-to-right or right-to-left)
                                [{ direction: 'rtl' }],

                                // Block formatting
                                ['blockquote', 'code-block'],

                                // Media embedding
                                ['link', 'image', 'video'],

                                // Clean (remove formatting)
                                ['clean'],
                            ]
                        }}
                        className="bg-white custom-quill" // Added 'custom-quill' class
                        style={{ height: '400px' }}
                    />
                </div>

                {/* Spacer Div */}
                <div style={{ height: '2rem' }}></div>
                {/* Submit */}
                <button
                    type="submit"
                    className={`px-6 py-3 mt-8 rounded font-semibold text-white
                      ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 transition'}`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Submitting...' : 'Submit Article'}
                </button>
            </form>
        </div>
    );
}

export default HelpArticleForm;